
import { computed, defineComponent, ref } from 'vue';
import SButton from './SButton.vue';
import { Game, Score } from '@/core/models';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import endOfDay from 'date-fns/endOfDay';
import { apiClient } from '@/core/api-client';
import Leaderboard from '@/components/Leaderboard.vue';
import Progress from '@/components/Progress.vue';
import { coreUtil } from '@/core/core-util';

export default defineComponent({
    components: {
        SButton,
        Leaderboard,
        Progress,
    },

    props: {
        initGame: { type: String },
        hideGameSelector: Boolean,
    },

    setup(props, ctx) {
        const date = ref(new Date());
        const disabledDates = ref({ from: new Date() });
        const game = props.initGame ? ref(props.initGame) : ref(Game.klondike);
        const gameId = ref(0);
        const loading = ref(false);
        const leaderboard = ref<Score[]>([]);
        const noChallenge = ref(false);

        const games = [
            Game.klondike,
            Game.klondike3,
            Game.freecell,
            Game.sudokuEasy,
            Game.spider1,
            Game.spider2,
            Game.pyramid,
            Game.tripeaks,
            Game.yukon,
            Game.scorpion,
        ];

        const startDateGame = () => {
            ctx.emit('start-game', {
                game: game.value,
                gameId: gameId.value,
            });
        };

        const dateStr = computed(() => {
            return format(date.value, 'MMM dd, yyyy');
        });

        const gameList = computed(() => {
            games.forEach((c) => {
                console.log('--', c, coreUtil.gameDisplayName(c));
            });
            return games.map((g) => ({
                game: g,
                title: coreUtil.gameDisplayName(g),
            }));
        });

        const hideColumns = computed(() => {
            if (game.value.toString().startsWith('sudoku')) {
                return ['score', 'moves'];
            }
            return null;
        });

        const loadData = async () => {
            loading.value = true;
            noChallenge.value = false;
            try {
                const sdate = date.value.toISOString().substr(0, 10);
                const res = await apiClient.getDailyChallenge({
                    date: sdate,
                    gameName: game.value.toString(),
                });
                console.log(res);
                gameId.value = res.gameId;
                leaderboard.value = res.leaderboard;
                noChallenge.value = gameId.value == 0;
            } catch {
                console.error('---| error loading data');
            } finally {
                loading.value = false;
            }
        };

        const addDay = () => {
            const nd = addDays(date.value, 1);
            if (isAfter(nd, endOfDay(new Date()))) {
                return;
            }
            date.value = addDays(date.value, 1);
            loadData();
        };

        const subtractDay = () => {
            date.value = addDays(date.value, -1);
            loadData();
        };

        loadData();

        return {
            date,
            disabledDates,
            startDateGame,
            dateStr,
            addDay,
            subtractDay,
            gameId,
            leaderboard,
            loading,
            noChallenge,
            gameList,
            game,
            loadData,
            hideColumns,
        };
    },
});
