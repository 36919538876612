<template>
    <div>
        <transition name="anim-scale">
            <div v-if="show" class="dialog-wrapper">
                <div
                    class="dialog bg-gray-800 shadow-2xl"
                    :class="{ 'dialog-fullscreen': isFull() }"
                    :style="dialogStyle"
                >
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, watch } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    props: {
        modelValue: { type: Boolean, required: true },
        size: { type: Number },
    },

    setup(props, ctx) {
        const show = ref(false);

        const close = () => {
            show.value = false;
            coreBus.overlayToggleCmd$.next(false);
            ctx.emit('input', false);
            ctx.emit('on-close');
        };

        const isFull = () => {
            if (props.size) {
                return false;
            }
            return window.innerWidth < 640;
        };

        subscribeTo(coreBus.overlayClickedEvent$, () => {
            close();
        });

        const dialogStyle = computed(() => {
            if (props.size) {
                return {
                    width: `${props.size}px`,
                };
            }
            return {};
        });

        watch(
            () => props.modelValue,
            (val) => {
                show.value = val;
                if (val && !isFull()) {
                    setTimeout(() => {
                        coreBus.overlayToggleCmd$.next(val);
                    });
                }
                if (!val) {
                    close();
                }
            }
        );

        return {
            show,
            isFull,
            dialogStyle,
        };
    },
});
</script>
<style>
.dialog {
    transform-origin: center center;
    max-width: 650px;
    width: 650px;
    max-height: 90%;
    overflow-y: auto;
    z-index: inherit;
    color: #fff;
    pointer-events: auto;
    position: fixed;
}
.dialog-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    outline: none;
}

.dialog-fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}
</style>
