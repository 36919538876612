<template>
    <div>
        <modal-dialog v-model="showModal" @on-close="handleClose">
            <div class="p-5">
                <div class="flex">
                    <div class="text-xl flex-grow">Statistics</div>
                    <div @click="handleClose" class="cursor-pointer">
                        <icon icon="times" :size="6"></icon>
                    </div>
                </div>
                <div class="mt-6">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="2" class="text-lg">{{ gameName }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in items" :key="i">
                                <td>{{ item.label }}</td>
                                <td
                                    v-if="item.percent !== undefined"
                                    :style="getPercentStyle(item.percent)"
                                >
                                    {{ item.content }} ({{ item.percent }}%)
                                </td>
                                <td v-if="item.percent === undefined">{{ item.content }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4 class="text-gray-400 text-sm my-3">
                        Collected since: {{ new Date(startTime).toDateString() }}
                    </h4>
                </div>
                <div v-show="!showConfirmDialog" class="text-center mt-5">
                    <s-button @click="showConfirmDialog = true" color="blue" :outlined="true"
                        >Clear Statistics</s-button
                    >
                </div>
                <div v-show="showConfirmDialog" class="mt-5 border p-2 rounded border-red-500">
                    Are you sure you want to clear your statistics? The game will start collecting
                    statistics again from today.
                    <div class="mt-3">
                        <s-button @click="showConfirmDialog = false">NO</s-button>
                        <s-button @click="clearStats" color="blue" class="ml-3">YES</s-button>
                    </div>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { tracking } from '@/core/tracking';
import { appQuery } from '@/state/app.query';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import SButton from '@/components/SButton.vue';
import { StatisticItem } from '@/core/models';
import { coreBus, ShowGameStatisticDialogCmd } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';
import { Stats } from '@/core/stats';
import { coreUtil } from '@/core/core-util';

export default defineComponent({
    components: { SButton, Icon, ModalDialog },

    setup() {
        const items = ref<StatisticItem[]>([]);
        const showModal = ref(false);
        const startTime = ref(0);
        const showConfirmDialog = ref(false);
        const gameName = ref('');
        let stats: Stats | null = null;
        let showCommand: ShowGameStatisticDialogCmd | null = null;

        subscribeTo(coreBus.showGameStatisticDialogCmd$, (cmd) => {
            console.log('----showGameStatisticDialogCmd$', cmd);
            showCommand = cmd;
            stats = new Stats(cmd.game);
            items.value = stats.getStatsItems(cmd);
            startTime.value = stats.getStartTime();
            showModal.value = true;
            gameName.value = coreUtil.gameDisplayName(cmd.game);
            tracking.event('statistics-opened', { eventCategory: appQuery.getActiveGame() });
        });

        const handleClose = () => {
            showModal.value = false;
        };

        const getPercentStyle = (percent: number) => {
            return {
                backgroundImage: `linear-gradient(left, #28452e, #28452e ${percent}%, transparent ${percent}%, transparent 100%)`,
                // @ts-expect-error foo
                // eslint-disable-next-line no-dupe-keys
                backgroundImage: `-webkit-linear-gradient(left, #28452e, #28452e ${percent}%, transparent ${percent}%, transparent 100%)`,
            };
        };

        const clearStats = () => {
            showConfirmDialog.value = false;
            if (stats) {
                stats.clearStats();
                if (showCommand) {
                    items.value = stats.getStatsItems(showCommand);
                }
                startTime.value = stats.getStartTime();
            }
        };

        return {
            items,
            showModal,
            startTime,
            showConfirmDialog,
            handleClose,
            getPercentStyle,
            clearStats,
            gameName,
        };
    },
});
</script>

<style scoped>
table {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border: thin solid hsla(0, 0%, 100%, 0.12);
}
table tr td {
    border-left: thin solid hsla(0, 0%, 100%, 0.12);
    border-right: thin solid hsla(0, 0%, 100%, 0.12);
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
    padding: 0.3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
}
table tr th {
    background-color: hsla(0, 0%, 100%, 0.12);
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 0.8rem;
    text-align: left;
    color: #aaa;
}
</style>
