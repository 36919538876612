
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    components: {
        ModalDialog,
        Icon,
    },

    setup() {
        const showModal = ref(false);

        subscribeTo(coreBus.showDonationDialogCmd$, () => {
            activityLog.sendActivity('donate-dialog-show');
            showModal.value = true;
        });

        const handleClose = () => {
            showModal.value = false;
            activityLog.sendActivity('donate-dialog-closed');
        };

        const handleFormSubmit = () => {
            activityLog.sendActivity('donate-clicked');
        };

        return {
            showModal,
            handleClose,
            handleFormSubmit,
        };
    },
});
