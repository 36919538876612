<template>
    <div class="contact-card">
        <div class="s-container-2 m-2">
            <div class="text-xl">Contact</div>
            <div class="mt-5">
                <div>
                    You can always contact us with any questions,<br />
                    suggestions or issues at
                </div>
                <div class="mt-5">
                    <a href="mailto:info@sologames.io">info@sologames.io</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.contact-card {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
</style>
