
import { defineComponent, reactive, PropType, watch, computed } from 'vue';
import { Game, Score } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import { coreLocalStorage } from '@/core/core-local-storage';
import ExpansionPanel from '@/components/ExpansionPanel.vue';

export default defineComponent({
    components: { ExpansionPanel },

    props: {
        items: { type: Object as PropType<Score[]>, required: true },
        gameId: { type: Number },
        game: { type: String, required: true },
    },

    setup(props, ctx) {
        const state = reactive({
            showNoDataInfo: false,
            noDataTitle: '',
        });

        watch(
            () => props.items,
            (items) => {
                state.showNoDataInfo = items.length <= 1;
                if (items.length == 0) {
                    state.noDataTitle = 'Why there are no scores in the list?';
                }
                if (items.length == 1 && items[0].isMyScore) {
                    state.noDataTitle = "Why I'm the only one in the list?";
                }
            }
        );

        const leaderboardItems = computed(() => {
            const { name } = coreLocalStorage.loadPlayerSettings() || 'anonymous';
            return props.items.map((d) => ({
                ...d,
                name: (d.isMyScore ? name : d.name) || 'anonymous',
            }));
        });

        const goToDailyChallenge = () => {
            ctx.emit('click-daily-challenge');
        };

        const formatTime = (time: number) => {
            return coreUtil.timeFormat(time);
        };

        const formatDate = (date: string) => {
            return coreUtil.toShortDateString(date);
        };

        const hideColumns = () => {
            const sudokuIndex = [
                Game.sudokuEasy,
                Game.sudokuMedium,
                Game.sudokuHard,
                Game.sudokuExpert,
                Game.minesweeperEasy,
                Game.minesweeperMedium,
                Game.minesweeperExpert,
            ].indexOf(props.game as Game);

            if (sudokuIndex >= 0) {
                return ['score', 'moves'];
            }
            return null;
        };

        const hideCol = (col: string) => {
            const colls = hideColumns();
            if (!colls) {
                return false;
            }

            return colls.indexOf(col) >= 0;
        };

        return {
            state,
            leaderboardItems,
            goToDailyChallenge,
            formatTime,
            formatDate,
            hideCol,
        };
    },
});
