<template>
    <nav class="nav-bar fixed w-full flex flex-row">
        <div class="leading-6 flex items-center">
            <button
                style="cursor: pointer"
                class="ml-4 hover:bg-gray-600 rounded-full focus:outline-none"
                @click="showMenu"
            >
                <icon icon="bars" />
            </button>
        </div>
        <div class="ml-3 p-4 text-lg flex-grow">
            {{ title }}
        </div>
        <div class="leading-6 flex items-center">
            <button
                style="cursor: pointer"
                class="mr-4 hover:bg-gray-600 rounded-full focus:outline-none"
                @click="showGamesNav"
            >
                <icon icon="th" />
            </button>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { coreBus } from '@/core/core-bus';
import Icon from '@/components/Icon.vue';
import { appQuery } from '@/state/app.query';
import { useObservable } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        const title = useObservable(appQuery.navbarTitle$, 'Solo Games');

        const showMenu = () => {
            coreBus.sideMenuToggleCmd$.next(true);
            coreBus.overlayToggleCmd$.next(true);
        };

        const showGamesNav = (e: MouseEvent) => {
            e.stopPropagation();
            coreBus.toggleGamesNavCmd$.next();
        };

        return {
            title,
            showMenu,
            showGamesNav,
        };
    },
});
</script>

<style scoped>
.nav-bar {
    background-color: rgba(40, 40, 40, 0.8);
    z-index: 5;
    color: white;
}
.nav-bar button {
    line-height: 3.5rem;
    vertical-align: middle;
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
}
</style>
