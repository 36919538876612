import { Observable, Subscription } from 'rxjs';

export abstract class Controller {
    protected subscription: Subscription = new Subscription();

    protected subscribeTo<T>(
        observable: Observable<T>,
        next?: (value: T) => void,
        // eslint-disable-next-line
        error?: (err: any) => void,
        complete?: () => void
    ) {
        const sub = observable.subscribe(next, error, complete);
        this.subscription.add(sub);
    }

    destroy() {
        this.subscription.unsubscribe();
    }
}
