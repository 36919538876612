<template>
    <div>
        <h4>Leaderboard for Game #{{ gameId }}</h4>
        <div class="mt-3">
            <table>
                <thead>
                    <tr>
                        <th style="padding-left: 5px; padding-right: 0">#</th>
                        <th>Name</th>
                        <th v-show="!hideCol('score')">Score</th>
                        <th v-show="!hideCol('moves')">moves</th>
                        <th>Time</th>
                        <!--th>Date</th-->
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, i) in leaderboardItems"
                        :key="i"
                        :class="{ 'my-score': item.isMyScore }"
                    >
                        <td style="padding-left: 5px; padding-right: 0">{{ i + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td v-show="!hideCol('score')">{{ item.score }}</td>
                        <td v-show="!hideCol('moves')">{{ item.moves }}</td>
                        <td>{{ formatTime(item.time) }}</td>
                        <!--td>{{ formatDate(item.createdAt) }}</td-->
                    </tr>
                </tbody>
            </table>
        </div>

        <expansion-panel v-show="state.showNoDataInfo" :title="state.noDataTitle" class="mt-5">
            The leaderboard table only show the scores for a particular game number, (a game number
            is a specific deck shuffle). Since there are a whole lot of options to shuffle the deck,
            the odds of someone else playing the particular game you just played are very, very low.
            If you want to compete with people the best thing to do is go to the
            <a @click="goToDailyChallenge" href="#">Daily Challenge</a> and pick a day.<br />
            Or challenge a friend for this game number.
        </expansion-panel>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, PropType, watch, computed } from 'vue';
import { Game, Score } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import { coreLocalStorage } from '@/core/core-local-storage';
import ExpansionPanel from '@/components/ExpansionPanel.vue';

export default defineComponent({
    components: { ExpansionPanel },

    props: {
        items: { type: Object as PropType<Score[]>, required: true },
        gameId: { type: Number },
        game: { type: String, required: true },
    },

    setup(props, ctx) {
        const state = reactive({
            showNoDataInfo: false,
            noDataTitle: '',
        });

        watch(
            () => props.items,
            (items) => {
                state.showNoDataInfo = items.length <= 1;
                if (items.length == 0) {
                    state.noDataTitle = 'Why there are no scores in the list?';
                }
                if (items.length == 1 && items[0].isMyScore) {
                    state.noDataTitle = "Why I'm the only one in the list?";
                }
            }
        );

        const leaderboardItems = computed(() => {
            const { name } = coreLocalStorage.loadPlayerSettings() || 'anonymous';
            return props.items.map((d) => ({
                ...d,
                name: (d.isMyScore ? name : d.name) || 'anonymous',
            }));
        });

        const goToDailyChallenge = () => {
            ctx.emit('click-daily-challenge');
        };

        const formatTime = (time: number) => {
            return coreUtil.timeFormat(time);
        };

        const formatDate = (date: string) => {
            return coreUtil.toShortDateString(date);
        };

        const hideColumns = () => {
            const sudokuIndex = [
                Game.sudokuEasy,
                Game.sudokuMedium,
                Game.sudokuHard,
                Game.sudokuExpert,
                Game.minesweeperEasy,
                Game.minesweeperMedium,
                Game.minesweeperExpert,
            ].indexOf(props.game as Game);

            if (sudokuIndex >= 0) {
                return ['score', 'moves'];
            }
            return null;
        };

        const hideCol = (col: string) => {
            const colls = hideColumns();
            if (!colls) {
                return false;
            }

            return colls.indexOf(col) >= 0;
        };

        return {
            state,
            leaderboardItems,
            goToDailyChallenge,
            formatTime,
            formatDate,
            hideCol,
        };
    },
});
</script>

<style scoped>
.my-score {
    color: #fb8c00 !important;
    font-weight: bold;
}
table {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border: thin solid hsla(0, 0%, 100%, 0.12);
}
table tr td {
    border-left: thin solid hsla(0, 0%, 100%, 0.12);
    border-right: thin solid hsla(0, 0%, 100%, 0.12);
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
    padding: 0.3rem;
    padding-left: 1rem;
    font-size: 0.9rem;
    white-space: nowrap;
}
table tr th {
    background-color: hsla(0, 0%, 100%, 0.12);
    padding: 0.3rem;
    padding-left: 1rem;
    font-size: 0.8rem;
    text-align: left;
    color: #aaa;
}
</style>
