
/* eslint-disable */
import { defineComponent, onBeforeUnmount, onMounted, computed } from 'vue';
import { appQuery } from '@/state/app.query';
import { appService } from '@/state/app.service';
import { tracking } from '@/core/tracking';
import { coreLocalStorage } from '@/core/core-local-storage';
import LeaderboardDialog from '@/views/LeaderBoardDialog.vue';
import DailyChallengeDialog from '@/views/DailyChallengeDialog.vue';
import ChallengeFriendDialog from '@/views/ChallengeFriendDialog.vue';
import PauseOverlay from '@/views/PauseOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/SideBar.vue';
import Overlay from '@/components/Overlay.vue';
import GameStatisticsDialog from '@/views/GameStatisticsDialog.vue';
import Notification from '@/components/Notification.vue';
import DonationDialog from '@/views/DonationDialog.vue';
import GamesNav from '@/components/GamesNav.vue';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import { controllers } from '@/controllers/controllers';
import CustomizeDialog from '@/views/CustomizeDialog.vue';
import { customize } from '@/core/customize';

export default defineComponent({
    components: {
        LeaderboardDialog,
        DailyChallengeDialog,
        ChallengeFriendDialog,
        PauseOverlay,
        NavBar,
        SideBar,
        Overlay,
        GameStatisticsDialog,
        Notification,
        GamesNav,
        DonationDialog,
        CustomizeDialog,
    },

    setup() {
        const showFooter = useObservable(appQuery.showFooter$);
        const customizeOptions = useObservable(appQuery.customizeOptions$);
        let disablePageScroll = false;
        const version = process.env.VERSION;

        // load user customize
        customize.loadOptions();

        subscribeTo(appQuery.disablePageScroll$, (disabled) => {
            disablePageScroll = disabled;
            window.document.body.style.overflow = disabled ? 'hidden' : 'visible';
        });

        const registerScrollEvents = () => {
            const el = document.querySelector('.main-container');
            if (el) {
                el.addEventListener('touchmove', (e) => {
                    if (disablePageScroll) {
                        e.preventDefault();
                    }
                });
            }
        };

        const createFingerprint = () => {
            // todo: move this to games to reduce file size
            // create finger print a head of time
            setTimeout(() => {
                coreLocalStorage.getOrCreateFingerprint();
            }, 1000);
        };

        const registerVisibilityEvents = () => {
            // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
            // Set the name of the hidden property and the change event for visibility
            let hidden = 'hidden';
            let visibilityChange = 'visibilitychange';
            if (typeof document.hidden !== 'undefined') {
                // Opera 12.10 and Firefox 18 and later support
                hidden = 'hidden';
                visibilityChange = 'visibilitychange';
                // eslint-disable-next-line
            } else if (typeof (document as any).msHidden !== 'undefined') {
                hidden = 'msHidden';
                visibilityChange = 'msvisibilitychange';
                // eslint-disable-next-line
            } else if (typeof (document as any).webkitHidden !== 'undefined') {
                hidden = 'webkitHidden';
                visibilityChange = 'webkitvisibilitychange';
            }
            document.addEventListener(visibilityChange, () => {
                // eslint-disable-next-line
                if ((document as any)[hidden]) {
                    appService.setAppHiddenStatus(true);
                } else {
                    appService.setAppHiddenStatus(false);
                }
            });

            // https://developer.mozilla.org/en-US/docs/Web/API/Window/blur_event
            window.addEventListener('blur', () => {
                appService.setAppHiddenStatus(true);
            });
            window.addEventListener('focus', () => {
                appService.setAppHiddenStatus(false);
            });

            // https://www.billerickson.net/code/track-pwa-install-in-google-analytics/
            // https://web.dev/customize-install/
            window.addEventListener('appinstalled', () => {
                try {
                    console.log('---: appinstalled');
                    tracking.event('app-installed', {});
                } catch (e) {
                    console.error(e);
                }
            });

            window.addEventListener('DOMContentLoaded', () => {
                let displayMode = 'browser';
                // eslint-disable-next-line
                if ((navigator as any).standalone) {
                    displayMode = 'standalone-ios';
                }
                if (window.matchMedia('(display-mode: standalone)').matches) {
                    displayMode = 'standalone';
                }
                // Log launch display mode to analytics
                if (displayMode != 'browser') {
                    try {
                        tracking.event('app-launch', {
                            eventLabel: displayMode,
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
                console.log('DISPLAY_MODE_LAUNCH:', displayMode);
            });
        };

        const bgStyle = computed(() =>{
            if (customizeOptions.value) {
                const bg = customize.getBackgroundByName(customizeOptions.value.background);
                let finalBg = bg.gradient;
                const pattern = customize.getPattern(customizeOptions.value.pattern);
                if (pattern) {
                    const color = bg.patternColor ? bg.patternColor : '#aaaaaa';
                    const svg = pattern.svg.replace('[color]', color.replace('#', '%23'))
                    finalBg = `url("${svg}"), ${bg.gradient}`;
                }
                return {
                    background: bg.solid,
                    // @ts-ignore
                    background: finalBg,
                };
            }
            return {};
        });

        onMounted(() => {
            registerScrollEvents();
            registerVisibilityEvents();
            createFingerprint();
            controllers.init();
        });

        onBeforeUnmount(() => {
            controllers.destroy();
        });

        return {
            showFooter,
            bgStyle,
            customizeOptions,
            version
        };
    },
});
