<template>
    <div class="bg-gray-700 rounded">
        <div @click="open = !open" class="py-3 px-5 text-base flex cursor-pointer">
            <div class="flex-grow">{{ title }}</div>
            <div class="mr-3"><icon icon="angle-down"></icon></div>
        </div>
        <div class="p-3" v-show="open">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    components: { Icon },

    props: {
        title: { type: String },
    },

    setup() {
        const open = ref(false);

        return {
            open,
        };
    },
});
</script>

<style scoped></style>
