import { Query } from '@datorama/akita';
import { AppState, appStore } from '@/state/app.store';

export class AppQuery extends Query<AppState> {
    showFooter$ = this.select((s) => s.showFooter);

    navbarTitle$ = this.select((s) => s.navbarTitle);

    activeGame$ = this.select((s) => s.activeGame);

    isAppHidden$ = this.select((s) => s.isAppHidden);

    disablePageScroll$ = this.select((s) => s.disablePageScroll);

    customizeOptions$ = this.select((s) => s.customizeOptions);

    getActiveGame() {
        return this.getValue().activeGame;
    }
}

export const appQuery = new AppQuery(appStore);
