import { Game, Suit } from '@/core/models';

class CoreUtil {
    private isFullScreen = false;

    timeFormat(time: number) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const seconds = time - hours * 3600 - minutes * 60;

        const sh = hours < 10 ? `0${hours}` : hours.toString();
        const sm = minutes < 10 ? `0${minutes}` : minutes.toString();
        const ss = seconds < 10 ? `0${seconds}` : seconds.toString();

        if (hours > 0) {
            return `${sh}:${sm}:${ss}`;
        } else {
            return `${sm}:${ss}`;
        }
    }

    toShortDateString(iso: string) {
        const ti = iso.indexOf('T');
        return iso.substring(0, ti);
    }

    dateStringToGameId(sd: string) {
        const s = sd.substr(2, 8).split('-').join('');
        return parseInt(s, 10);
    }

    getAllGames() {
        return [
            Game.klondike,
            Game.klondike3,
            Game.freecell,
            Game.sudokuEasy,
            Game.sudokuMedium,
            Game.sudokuHard,
            Game.sudokuExpert,
            Game.pyramid,
            Game.spider1,
            Game.spider2,
            Game.spider4,
            Game.tripeaks,
            Game.yukon,
            Game.fortyThieves,
            Game.scorpion,
            Game.canfield1,
            Game.canfield3,
            Game.golf,
        ];
    }

    gameDisplayName(game: Game) {
        switch (game) {
            case Game.klondike:
                return 'Klondike (Turn 1)';
            case Game.klondike3:
                return 'Klondike (Turn 3)';
            case Game.freecell:
                return 'FreeCell';
            case Game.sudokuEasy:
                return 'Sudoku (easy)';
            case Game.sudokuMedium:
                return 'Sudoku (medium)';
            case Game.sudokuHard:
                return 'Sudoku (hard)';
            case Game.sudokuExpert:
                return 'Sudoku (expert)';
            case Game.pyramid:
                return 'Pyramid';
            case Game.spider1:
                return 'Spider (1 suit)';
            case Game.spider2:
                return 'Spider (2 suits)';
            case Game.spider4:
                return 'Spider (4 suits)';
            case Game.tripeaks:
                return 'Tri Peaks';
            case Game.yukon:
                return 'Yukon';
            case Game.fortyThieves:
                return 'Forty Thieves';
            case Game.scorpion:
                return 'scorpion';
            case Game.canfield1:
                return 'Canfield (Turn 1)';
            case Game.canfield3:
                return 'Canfield (Turn 3)';
            case Game.golf:
                return 'Golf';
        }
        return '';
    }

    getGameAbbreviation(game: Game) {
        switch (game) {
            case Game.klondike:
                return 'k1';
            case Game.klondike3:
                return 'k3';
            case Game.freecell:
                return 'fc';
            case Game.sudokuEasy:
                return 'se';
            case Game.sudokuMedium:
                return 'sm';
            case Game.sudokuHard:
                return 'sh';
            case Game.sudokuExpert:
                return 'sx';
            case Game.pyramid:
                return 'py';
            case Game.minesweeperEasy:
                return 'me';
            case Game.minesweeperMedium:
                return 'mm';
            case Game.minesweeperExpert:
                return 'mx';
            case Game.spider1:
                return 's1';
            case Game.spider2:
                return 's2';
            case Game.spider4:
                return 's4';
            case Game.tripeaks:
                return 'tp';
            case Game.yukon:
                return 'yk';
            case Game.fortyThieves:
                return 'ft';
            case Game.scorpion:
                return 'sc';
            case Game.canfield1:
                return 'c1';
            case Game.canfield3:
                return 'c3';
            case Game.golf:
                return 'gl';
        }
        return '';
    }

    getGameFromAbbreviation(abv: string) {
        switch (abv) {
            case 'k1':
                return Game.klondike;
            case 'k3':
                return Game.klondike3;
            case 'fc':
                return Game.freecell;
            case 'se':
                return Game.sudokuEasy;
            case 'sm':
                return Game.sudokuMedium;
            case 'sh':
                return Game.sudokuHard;
            case 'sx':
                return Game.sudokuExpert;
            case 'py':
                return Game.pyramid;
            case 'me':
                return Game.minesweeperEasy;
            case 'mm':
                return Game.minesweeperMedium;
            case 'mx':
                return Game.minesweeperExpert;
            case 's1':
                return Game.spider1;
            case 's2':
                return Game.spider2;
            case 's4':
                return Game.spider4;
            case 'tp':
                return Game.tripeaks;
            case 'yk':
                return Game.yukon;
            case 'ft':
                return Game.fortyThieves;
            case 'sc':
                return Game.scorpion;
            case 'c1':
                return Game.canfield1;
            case 'c2':
                return Game.canfield3;
            case 'gl':
                return Game.golf;
        }
        return null;
    }

    getGameRoute(game: Game) {
        switch (game) {
            case Game.klondike:
                return '/klondike/play';
            case Game.klondike3:
                return '/klondike3/play';
            case Game.freecell:
                return '/freecell/play';
            case Game.sudokuEasy:
                return '/sudoku-easy/play';
            case Game.sudokuMedium:
                return '/sudoku-medium/play';
            case Game.sudokuHard:
                return '/sudoku-hard/play';
            case Game.sudokuExpert:
                return '/sudoku-expert/play';
            case Game.pyramid:
                return '/pyramid/play';
            case Game.minesweeperEasy:
                return '/minesweeper-easy/play';
            case Game.minesweeperMedium:
                return '/minesweeper-medium/play';
            case Game.minesweeperExpert:
                return '/minesweeper-expert/play';
            case Game.spider1:
                return '/spider1/play';
            case Game.spider2:
                return '/spider2/play';
            case Game.spider4:
                return '/spider4/play';
            case Game.tripeaks:
                return '/tripeaks/play';
            case Game.yukon:
                return '/yukon/play';
            case Game.fortyThieves:
                return '/forty-thieves/play';
            case Game.scorpion:
                return '/scorpion/play';
            case Game.canfield1:
                return '/canfield1/play';
            case Game.canfield3:
                return '/canfield3/play';
            case Game.golf:
                return '/golf/play';
        }
        return '/';
    }

    async delay(millis: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, millis);
        });
    }

    isMobile() {
        /* eslint-disable no-useless-escape */
        return (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4)
            )
        );
    }

    requestFullscreen() {
        try {
            // eslint-disable-next-line
            const el = window.document.documentElement as any;
            if (el.requestFullscreen) {
                this.isFullScreen = true;
                return el.requestFullscreen();
            } else if (el.mozRequestFullScreen) {
                this.isFullScreen = true;
                return el.mozRequestFullScreen();
            } else if (el.webkitRequestFullScreen) {
                this.isFullScreen = true;
                return el.webkitRequestFullScreen();
            } else if (el.msRequestFullscreen) {
                this.isFullScreen = true;
                return el.msRequestFullscreen();
            }
        } catch {
            console.log('--error full screen');
        }
    }

    exitFullscreen() {
        if (!this.isFullScreen) {
            return;
        }
        try {
            this.isFullScreen = false;
            // eslint-disable-next-line
            const doc = window.document as any;
            if (doc.exitFullscreen) {
                return doc.exitFullscreen();
            } else if (doc.mozCancelFullScreen) {
                return doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
                return doc.webkitExitFullscreen();
            } else if (doc.msExitFullscreen) {
                return doc.msExitFullscreen();
            }
        } catch {
            this.isFullScreen = false;
        }
    }

    isRedSuit(suit: Suit) {
        return suit == Suit.Heart || suit == Suit.Diamond;
    }

    isBlackSuit(suit: Suit) {
        return suit == Suit.Spade || suit == Suit.Club;
    }

    isSameSuitColor(suit1: Suit, suit2: Suit) {
        return (
            (this.isRedSuit(suit1) && this.isRedSuit(suit2)) ||
            (this.isBlackSuit(suit1) && this.isBlackSuit(suit2))
        );
    }

    getFoundationSuitByIndex(index: number) {
        switch (index) {
            case 1:
            case 5:
                return Suit.Heart;
            case 2:
            case 6:
                return Suit.Spade;
            case 3:
            case 7:
                return Suit.Diamond;
            case 4:
            case 8:
                return Suit.Club;
        }

        throw new Error('Invalid index');
    }

    getFoundation4IndexForSuit(suit: Suit) {
        if (suit == Suit.Heart) {
            return 1;
        } else if (suit == Suit.Spade) {
            return 2;
        } else if (suit == Suit.Diamond) {
            return 3;
        } else {
            return 4;
        }
    }
}

export const coreUtil = new CoreUtil();
