import axios from 'axios';
import { Score } from '@/core/models';

export type AddScoreInput = {
    gameId: number;
    gameName: string;
    score: number;
    moves: number;
    time: number;
    undos: number;
    hints: number;
};

export type UpdatePlayerInput = {
    name: string;
};

export type GetDailyChallengeInput = {
    gameName: string;
    date: string;
};

export type GetDailyChallengeOutput = {
    gameId: number;
    date: string;
    leaderboard: Score[];
};

export type AddActivityInput = {
    gameId?: number | undefined;
    gameName?: string | undefined;
    activityName: string;
    activityInfo?: string | undefined;
};

class ApiClient {
    private apiUrl = 'http://localhost:3000';

    constructor() {
        if (process.env.NODE_ENV === 'production') {
            this.apiUrl = 'https://solo-api-ufbn74qrnq-ue.a.run.app';
        }
    }

    async getLeaderBoard(gameName: string, gameId: number): Promise<Score[]> {
        try {
            const url = `${this.apiUrl}/score/leaderboard/${gameName}/${gameId}`;
            const res = await axios.get(url);
            return res.data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async addScore(input: AddScoreInput): Promise<Score[]> {
        try {
            const url = `${this.apiUrl}/score/add`;
            const res = await axios.post(url, input);
            return res.data;
        } catch (e) {
            console.error(e);
            // todo: put in local sotrage queue
            return [];
        }
    }

    async updatePlayer(input: Partial<UpdatePlayerInput>) {
        try {
            const url = `${this.apiUrl}/player/update`;
            await axios.post(url, input);
        } catch (e) {
            console.error(e);
            // todo: put in local sotrage queue
        }
    }

    async getWinnableGames(gameName: string) {
        try {
            const url = `${this.apiUrl}/score/games/winnable/${gameName}`;
            const res = await axios.get(url);
            // eslint-disable-next-line
            return (res.data as any[]).map((d) => d.gameId);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async getDailyChallenge(input: GetDailyChallengeInput): Promise<GetDailyChallengeOutput> {
        const url = `${this.apiUrl}/score/challenge?gameName=${input.gameName}&date=${input.date}`;
        const res = await axios.get(url);
        return res.data as GetDailyChallengeOutput;
    }

    async addActivity(input: AddActivityInput): Promise<void> {
        try {
            const url = `${this.apiUrl}/activity/add`;
            const res = await axios.post(url, input);
            return res.data;
        } catch (e) {
            console.error(e);
        }
    }
}

export const apiClient = new ApiClient();
