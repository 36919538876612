<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Solitaire Klondike</h1>
            <div class="mt-8">
                <router-link to="/klondike/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3"
                        >Play Klondike (Turn One)</span
                    ></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/klondike3/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3"
                        >Play Klondike (Turn Three)</span
                    ></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/klondike" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-10 p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The object of the game is to build the four foundations up in ascending suit
                    sequence from Ace to King
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    The game is played with a single pack of 52 playing cards. 28 cards are dealt
                    face down to form the tableau which consists of 7 columns of 1,2,3,4,5,6 and 7
                    overlapping cards from left to right respectively. The exposed card at the end
                    of each tableau column is turned face up. The remaining cards are placed face
                    down to form the stock. The game then begins.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    As each Ace becomes available it may be transferred to a row above the tableau
                    to start one of the four foundations. The foundations are built up in ascending
                    suit sequence to the King. The exposed card of a tableau column may be
                    transferred to a foundation of the same suit if it follows the ascending
                    sequence or to the exposed card of another column if it forms a descending
                    sequence of alternating colours, e.g.
                    <span class="text-red-600 font-bold">5♥</span> on
                    <span class="text-black font-bold">6♣</span> or
                    <span class="text-red-600 font-bold">Q♦</span> on
                    <span class="text-black font-bold">K♠</span>. A complete packed column of face
                    up cards may also be transferred to the exposed card of another tableau column
                    if the join follows the same descending sequence of alternating colours. If the
                    movement of a tableau card exposes a face down card, then it is turned face up.
                    When a tableau column is completely cleared out, the space may only be filled by
                    a King or a packed column headed by a King. When click on the stock card, the
                    top one/three cards from the stock are dealt face up. The top exposed card of
                    the waste pile is always available for play to the foundations or tableau. When
                    the stock has been exhausted, the waste pile is picked up and turned over to
                    form a new stock and the game continues. This procedure is continued until the
                    game eventually blocks or is won.
                </p>

                <div class="mt-8">
                    <img class="mx-auto" src="/img/klondike-layout.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.klondike);
        apiClient.getWinnableGames(Game.klondike3);
    },
});
</script>

<style scoped></style>
