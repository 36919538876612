
import { ref, defineComponent, computed, watch } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    props: {
        modelValue: { type: Boolean, required: true },
        size: { type: Number },
    },

    setup(props, ctx) {
        const show = ref(false);

        const close = () => {
            show.value = false;
            coreBus.overlayToggleCmd$.next(false);
            ctx.emit('input', false);
            ctx.emit('on-close');
        };

        const isFull = () => {
            if (props.size) {
                return false;
            }
            return window.innerWidth < 640;
        };

        subscribeTo(coreBus.overlayClickedEvent$, () => {
            close();
        });

        const dialogStyle = computed(() => {
            if (props.size) {
                return {
                    width: `${props.size}px`,
                };
            }
            return {};
        });

        watch(
            () => props.modelValue,
            (val) => {
                show.value = val;
                if (val && !isFull()) {
                    setTimeout(() => {
                        coreBus.overlayToggleCmd$.next(val);
                    });
                }
                if (!val) {
                    close();
                }
            }
        );

        return {
            show,
            isFull,
            dialogStyle,
        };
    },
});
