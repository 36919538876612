
import { ref, defineComponent } from 'vue';
import { tracking } from '@/core/tracking';
import { appQuery } from '@/state/app.query';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import SButton from '@/components/SButton.vue';
import { StatisticItem } from '@/core/models';
import { coreBus, ShowGameStatisticDialogCmd } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';
import { Stats } from '@/core/stats';
import { coreUtil } from '@/core/core-util';

export default defineComponent({
    components: { SButton, Icon, ModalDialog },

    setup() {
        const items = ref<StatisticItem[]>([]);
        const showModal = ref(false);
        const startTime = ref(0);
        const showConfirmDialog = ref(false);
        const gameName = ref('');
        let stats: Stats | null = null;
        let showCommand: ShowGameStatisticDialogCmd | null = null;

        subscribeTo(coreBus.showGameStatisticDialogCmd$, (cmd) => {
            console.log('----showGameStatisticDialogCmd$', cmd);
            showCommand = cmd;
            stats = new Stats(cmd.game);
            items.value = stats.getStatsItems(cmd);
            startTime.value = stats.getStartTime();
            showModal.value = true;
            gameName.value = coreUtil.gameDisplayName(cmd.game);
            tracking.event('statistics-opened', { eventCategory: appQuery.getActiveGame() });
        });

        const handleClose = () => {
            showModal.value = false;
        };

        const getPercentStyle = (percent: number) => {
            return {
                backgroundImage: `linear-gradient(left, #28452e, #28452e ${percent}%, transparent ${percent}%, transparent 100%)`,
                // @ts-expect-error foo
                // eslint-disable-next-line no-dupe-keys
                backgroundImage: `-webkit-linear-gradient(left, #28452e, #28452e ${percent}%, transparent ${percent}%, transparent 100%)`,
            };
        };

        const clearStats = () => {
            showConfirmDialog.value = false;
            if (stats) {
                stats.clearStats();
                if (showCommand) {
                    items.value = stats.getStatsItems(showCommand);
                }
                startTime.value = stats.getStartTime();
            }
        };

        return {
            items,
            showModal,
            startTime,
            showConfirmDialog,
            handleClose,
            getPercentStyle,
            clearStats,
            gameName,
        };
    },
});
