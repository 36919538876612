<template>
    <button
        class="py-2 px-4 capitalize border tracking-wide font-medium rounded s-button focus:outline-none text-white"
        :class="dynamicClass"
    >
        <slot></slot>
    </button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        color: { type: String },
        outlined: { type: Boolean },
    },

    setup(props) {
        const dynamicClass = computed(() => {
            const arr = [];
            if (props.color) {
                arr.push(props.color);
            } else {
                arr.push('blue');
            }
            if (props.outlined) {
                arr.push('outlined');
                arr.push('bg-opacity-25');
            }
            return arr.join(' ');
        });

        return {
            dynamicClass,
        };
    },
});
</script>
