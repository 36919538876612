<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Minesweeper</h1>
            <div class="mt-8">
                <router-link to="/minesweeper-easy/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Minesweeper (easy)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/minesweeper-medium/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Minesweeper (medium)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/minesweeper-expert/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Minesweeper (expert)</span></router-link
                >
            </div>
            <!--div class="mt-10">
            <router-link
                to="/daily-challenge/freecell"
                class="rounded-md text-xl py-3 border border-green-600 shadow-gl px-5 bg-green-700 hover:bg-green-600 focus:outline-none"
            >
                <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
            >
        </div-->
            <div class="mt-10 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>
                <p>
                    The rule of the game is simple, the number on a block shows the number of mines
                    adjacent to it and you have to flag all the mines
                </p>
                <p>
                    To mark a block with flag press and hold until the flag show up. To mark a block
                    with question mark press and hold until the flag show up.
                </p>
                <p>
                    if you use a mouse you can right click on a block to mark it with flag or
                    question mark
                </p>
                <p>
                    To win, all non-mine cells must be uncovered. At this stage, the timer is
                    stopped. Commonly all mine cells are also flagged, but this is not required
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.freecell);
    },
});
</script>

<style scoped></style>
