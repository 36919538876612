<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto py-3 text-center">
        <div class="s-container-2 rounded p-2 py-4 md:p-5">
            <daily-challenge @start-game="startGame" :init-game="initGame" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import DailyChallenge from '@/components/DailyChallenge.vue';
import { Game } from '@/core/models';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: {
        DailyChallenge,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        const initGame = route.params.game ? ref(route.params.game) : ref(Game.klondike);

        const startGame = (d: { game: Game; gameId: number }) => {
            const url = `/${d.game.toString()}/play/${d.gameId}`;
            router.push(url);
        };

        return {
            startGame,
            initGame,
        };
    },
});
</script>

<style scoped></style>
