<template>
    <div class="games-wrapper mt-5">
        <div class="grid grid-cols-1 sm:grid-cols-2 justify-items-center">
            <div v-for="game in games" :key="game.key">
                <router-link
                    :to="game.path"
                    class="game-box flex shadow-xl border-gray-400 border hover:shadow-outline rounded bg-white mb-5 p-3"
                    :style="`background-color: ${game.bgColor}; width: 300px`"
                >
                    <div class="flex-grow">
                        <div class="text-2xl text-white">{{ game.title }}</div>
                        <div class="text-sm text-gray-400">{{ game.subTitle }}</div>
                    </div>
                    <div
                        class="h-24 w-24 bg-cover rounded-lg"
                        :style="`background-image: url('${game.image}'); background-color: ${game.imgBgColor};`"
                    ></div>
                </router-link>
            </div>
        </div>
        <div class="mx-auto text-center mt-8">More games coming soon...</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    setup() {
        const games = ref([
            {
                key: 'klondike1',
                title: 'Klondike',
                subTitle: '',
                path: '/klondike',
                image: '/img/thumb/klondike.png',
                bgColor: 'rgb(149, 33, 117)',
                imgBgColor: 'rgb(195, 78, 221)',
            },
            {
                key: 'freeCell',
                title: 'FreeCell',
                subTitle: '',
                path: '/freecell',
                image: '/img/thumb/freecell.png',
                bgColor: '#bf360c',
                imgBgColor: '#ff8a65',
            },
            {
                key: 'spider',
                title: 'Spider',
                subTitle: '',
                path: '/spider',
                image: '/img/thumb/spider.png',
                bgColor: '#37474F',
                imgBgColor: '#90A4AE',
            },
            {
                key: 'tripeaks',
                title: 'Tri Peaks',
                subTitle: '',
                path: '/tripeaks',
                image: '/img/thumb/tripeak.png',
                bgColor: '#004D40',
                imgBgColor: '#26A69A',
            },
            {
                key: 'sudoku',
                title: 'Sudoku',
                subTitle: '',
                path: '/sudoku',
                image: '/img/thumb/sudoku.png',
                bgColor: '#4e342e',
                imgBgColor: '#bcaaa4',
            },
            {
                key: 'pyramid',
                title: 'Pyramid',
                subTitle: '',
                path: '/pyramid',
                image: '/img/thumb/pyramid.png',
                bgColor: '#22543D',
                imgBgColor: '#48BB78',
            },
            {
                key: 'minesweeper',
                title: 'Minesweeper',
                subTitle: '',
                path: '/minesweeper',
                image: '/img/thumb/mine.png',
                bgColor: '#742A2A',
                imgBgColor: '#FC8181',
            },
            {
                key: 'yukon',
                title: 'Yukon',
                subTitle: '',
                path: '/yukon',
                image: '/img/thumb/klondike.png',
                bgColor: '#311B92',
                imgBgColor: '#7E57C2',
            },
            {
                key: 'forty',
                title: 'Forty Thieves',
                subTitle: '',
                path: '/forty-thieves',
                image: '/img/thumb/forty.png',
                bgColor: '#1B5E20',
                imgBgColor: '#7CB342',
            },
            {
                key: 'scorpion',
                title: 'Scorpion',
                subTitle: '',
                path: '/scorpion',
                image: '/img/thumb/scorpion.png',
                bgColor: '#D84315',
                imgBgColor: '#FFAB91',
            },
            {
                key: 'canfield',
                title: 'Canfield',
                subTitle: '',
                path: '/canfield',
                image: '/img/thumb/klondike.png',
                bgColor: '#6A1B9A',
                imgBgColor: '#CE93D8',
            },
            {
                key: 'golf',
                title: 'Golf',
                subTitle: '',
                path: '/golf',
                image: '/img/thumb/golf.png',
                bgColor: '#78350F',
                imgBgColor: '#D97706',
            },
        ]);

        return {
            games,
        };
    },
});
</script>

<style scoped>
.games-wrapper {
    width: 100%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}
.game-box:hover {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7);
}
</style>
