<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Spider</h1>
            <div class="mt-8">
                <router-link to="/spider1/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Spider (1 suit)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/spider2/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Spider (2 suits)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/spider4/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Spider (4 suits)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/spider1" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <!--div class="mt-16 bg-blue-800 rounded p-3 text-left">
            <h2 class="text-2xl text-center mb-3">How to Play</h2>
        </div-->
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.freecell);
    },
});
</script>

<style scoped></style>
