<template>
    <div>
        <transition name="anim-slide-top">
            <div v-show="show" class="games-menu w-full md:w-2/6">
                <div class="mx-1 rounded p-2 bg-gray-800 flex flex-wrap">
                    <div v-for="game in games" :key="game.key" class="m-2">
                        <div
                            @click="itemClick(game)"
                            class="h-20 w-20 bg-cover rounded-lg hover:shadow-outline cursor-pointer"
                            :style="`background-image: url('${game.image}'); background-color: ${game.imgBgColor};`"
                        ></div>
                        <div class="text-center text-sm">{{ game.title }}</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import { useRouter } from 'vue-router';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    setup() {
        const games = ref([
            {
                key: 'klondike1',
                title: 'Klondike',
                path: '/klondike',
                image: '/img/thumb/klondike.png',
                bgColor: 'rgb(149, 33, 117)',
                imgBgColor: 'rgb(195, 78, 221)',
            },
            {
                key: 'freeCell',
                title: 'FreeCell',
                path: '/freecell',
                image: '/img/thumb/freecell.png',
                bgColor: '#bf360c',
                imgBgColor: '#ff8a65',
            },
            {
                key: 'sudoku',
                title: 'Sudoku',
                path: '/sudoku',
                image: '/img/thumb/sudoku.png',
                bgColor: '#4e342e',
                imgBgColor: '#bcaaa4',
            },
            {
                key: 'pyramid',
                title: 'Pyramid',
                subTitle: '',
                path: '/pyramid',
                image: '/img/thumb/pyramid.png',
                bgColor: '#22543D',
                imgBgColor: '#48BB78',
            },
            {
                key: 'minesweeper',
                title: 'Minesweeper',
                subTitle: '',
                path: '/minesweeper',
                image: '/img/thumb/mine.png',
                bgColor: '#742A2A',
                imgBgColor: '#FC8181',
            },
            {
                key: 'spider',
                title: 'Spider',
                subTitle: '',
                path: '/spider',
                image: '/img/thumb/spider.png',
                bgColor: '#37474F',
                imgBgColor: '#90A4AE',
            },
            {
                key: 'tripeaks',
                title: 'Tri Peaks',
                subTitle: '',
                path: '/tripeaks',
                image: '/img/thumb/tripeak.png',
                bgColor: '#004D40',
                imgBgColor: '#26A69A',
            },
            {
                key: 'yukon',
                title: 'Yukon',
                subTitle: '',
                path: '/yukon',
                image: '/img/thumb/klondike.png',
                bgColor: '#311B92',
                imgBgColor: '#7E57C2',
            },
            {
                key: 'forty',
                title: 'Forty Thieves',
                subTitle: '',
                path: '/forty-thieves',
                image: '/img/thumb/forty.png',
                bgColor: '#1B5E20',
                imgBgColor: '#7CB342',
            },
            {
                key: 'scorpion',
                title: 'Scorpion',
                subTitle: '',
                path: '/scorpion',
                image: '/img/thumb/scorpion.png',
                bgColor: '#D84315',
                imgBgColor: '#FFAB91',
            },
            {
                key: 'canfield',
                title: 'Canfield',
                subTitle: '',
                path: '/canfield',
                image: '/img/thumb/klondike.png',
                bgColor: '#6A1B9A',
                imgBgColor: '#CE93D8',
            },
            {
                key: 'golf',
                title: 'Golf',
                subTitle: '',
                path: '/golf',
                image: '/img/thumb/golf.png',
                bgColor: '#78350F',
                imgBgColor: '#D97706',
            },
        ]);

        const show = ref(false);
        const router = useRouter();

        subscribeTo(coreBus.toggleGamesNavCmd$, () => {
            show.value = !show.value;
        });

        // eslint-disable-next-line
        const itemClick = (item: any) => {
            show.value = false;
            setTimeout(() => {
                router.push({ path: item.path });
            }, 10);
            activityLog.sendActivity('open-game', 'top-nav');
        };

        const onBodyClick = () => {
            if (show.value) {
                show.value = false;
            }
        };
        document.body.addEventListener('click', onBodyClick);

        return {
            games,
            show,
            itemClick,
        };
    },
});
</script>

<style scoped>
.games-menu {
    position: fixed;
    top: 4rem;
    right: 0;
    z-index: 999;
}
</style>
