<template>
    <div>
        <modal-dialog v-model="showModal">
            <div class="p-5">
                <div class="flex">
                    <div class="text-xl flex-grow">Show your support</div>
                    <div @click="handleClose" class="cursor-pointer">
                        <icon icon="times" :size="6"></icon>
                    </div>
                </div>
                <div class="mt-6">
                    <div>
                        Please help us maintaining this site, and add new games. <br />
                        any donation amount will be greatly appreciated.
                    </div>
                    <div class="mt-6">
                        <form
                            action="https://www.paypal.com/donate"
                            @submit="handleFormSubmit"
                            method="post"
                            target="_top"
                        >
                            <input type="hidden" name="cmd" value="_donations" />
                            <input type="hidden" name="business" value="NJSL7YMF6A2YN" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <input
                                type="image"
                                src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                                border="0"
                                name="submit"
                                title="PayPal - The safer, easier way to pay online!"
                                alt="Donate with PayPal button"
                            />
                            <img
                                alt=""
                                border="0"
                                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                                width="1"
                                height="1"
                            />
                        </form>
                        <!-- https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=NJSL7YMF6A2YN&currency_code=USD -->
                    </div>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    components: {
        ModalDialog,
        Icon,
    },

    setup() {
        const showModal = ref(false);

        subscribeTo(coreBus.showDonationDialogCmd$, () => {
            activityLog.sendActivity('donate-dialog-show');
            showModal.value = true;
        });

        const handleClose = () => {
            showModal.value = false;
            activityLog.sendActivity('donate-dialog-closed');
        };

        const handleFormSubmit = () => {
            activityLog.sendActivity('donate-clicked');
        };

        return {
            showModal,
            handleClose,
            handleFormSubmit,
        };
    },
});
</script>

<style scoped></style>
