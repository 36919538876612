type PageMeta = {
    title: string;
    description: string;
};

class PageMetaService {
    private readonly data: { [id: string]: PageMeta } = {};

    constructor() {
        const gameDesc =
            'Play [game] online for free!. Simple gameplay, does not require registration or download and provides detailed statistics.';

        this.data = {
            default: {
                title: 'Solo Games - Play classic cards games and more',
                description:
                    'Play classic cards games like solitaire, FreeCell, Spider for free. Full screen, no download or registration needed.',
            },
            klondike: {
                title: 'Klondike Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Klondike Solitaire'),
            },
            freeCell: {
                title: 'FreeCell Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'FreeCell Solitaire'),
            },
            spider: {
                title: 'Spider Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Spider Solitaire'),
            },
            triPeaks: {
                title: 'Tri Peaks Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Tri Peaks Solitaire'),
            },
            pyramid: {
                title: 'Pyramid Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Pyramid Solitaire'),
            },
            sudoku: {
                title: 'Sudoku | Play it online',
                description: gameDesc.replace('[game]', 'Sudoku'),
            },
            minesweeper: {
                title: 'Minesweeper | Play it online',
                description: gameDesc.replace('[game]', 'Minesweeper'),
            },
            yukon: {
                title: 'Yukon Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Yukon Solitaire'),
            },
            'forty-thieves': {
                title: 'Forty Thieves Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Forty Thieves Solitaire'),
            },
            scorpion: {
                title: 'Scorpion Solitaire | Play it online',
                description: gameDesc.replace('[game]', 'Scorpion Solitaire'),
            },
        };
    }

    updateMetaForRoutePath(path: string) {
        try {
            const keys = Object.keys(this.data);
            let selectedKey = 'default';
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (path.toLowerCase().startsWith(`/${key.toLowerCase()}`)) {
                    selectedKey = key;
                    break;
                }
            }

            this.modifyPageMeta(this.data[selectedKey]);
        } catch {
            console.log('error setting meta');
        }
    }

    private modifyPageMeta(meta: PageMeta) {
        console.log('--meta', meta);

        document.title = meta.title;

        const desc = document.querySelector('meta[name="description"]');
        if (desc) {
            desc.setAttribute('content', meta.description);
        }
    }
}

export const pageMetaService = new PageMetaService();
