import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import NProgress from 'nprogress';
import Home from './views/Home.vue';
import Contact from '@/views/Contact.vue';
import Settings from '@/views/Settings.vue';
import { appService } from '@/state/app.service';
import { Game } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import KlondikeIntro from '@/games/klondike/views/Intro.vue';
import FreeCellIntro from '@/games/freecell/views/Intro.vue';
import SudokuIntro from '@/games/sudoku/views/Intro.vue';
import PyramidIntro from '@/games/pyramid/views/Intro.vue';
import DailyChallengeView from '@/views/DailyChallengeView.vue';
import Redirect from '@/views/Redirect.vue';
import MinesweeperIntro from '@/games/minesweeper/views/Intro.vue';
import SpiderIntro from '@/games/spider/views/Intro.vue';
import TriPeaksIntro from '@/games/tripeaks/views/Intro.vue';
import YukonIntro from '@/games/yukon/views/Intro.vue';
import FortyIntro from '@/games/forty/views/Intro.vue';
import ScorpionIntro from '@/games/scorpion/views/Intro.vue';
import CanfieldIntro from '@/games/canfield/views/Intro.vue';
import GolfIntro from '@/games/golf/views/Intro.vue';
import Statistics from '@/views/Statistics.vue';
import { tracking } from '@/core/tracking';
import { coreUtil } from '@/core/core-util';
import { pageMetaService } from '@/core/page-meta';

let timerId: number | null = null;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: Statistics,
    },
    {
        path: '/daily-challenge/:game?',
        name: 'daily-challenge',
        component: DailyChallengeView,
    },
    {
        path: '/r/:gameKey',
        name: 'redirect',
        component: Redirect,
    },
    {
        path: '/game-history',
        name: 'game-history',
        component: () => import(/* webpackChunkName: "game-history" */ './views/GameHistory.vue'),
    },
    //--- Klondike
    {
        path: '/klondike',
        name: 'klondike',
        component: KlondikeIntro,
    },
    {
        path: '/klondike/play/:gameId?',
        name: 'klondike-play',
        component: () =>
            import(/* webpackChunkName: "klondike" */ './games/klondike/views/Klondike.vue'),
    },
    {
        path: '/klondike3/play/:gameId?',
        name: 'klondike3-play',
        component: () =>
            import(/* webpackChunkName: "klondike" */ './games/klondike/views/Klondike.vue'),
    },
    //--- FreeCell
    {
        path: '/freecell',
        name: 'freecell',
        component: FreeCellIntro,
    },
    {
        path: '/freecell/play/:gameId?',
        name: 'freecell-play',
        component: () =>
            import(/* webpackChunkName: "freecell" */ './games/freecell/views/Freecell.vue'),
    },
    //--- Sudoku
    {
        path: '/sudoku',
        name: 'sudoku',
        component: SudokuIntro,
    },
    {
        path: '/sudoku-easy/play/:gameId?',
        name: 'sudoku-easy',
        component: () => import(/* webpackChunkName: "sudoku" */ './games/sudoku/views/Sudoku.vue'),
    },
    {
        path: '/sudoku-medium/play/:gameId?',
        name: 'sudoku-medium',
        component: () => import(/* webpackChunkName: "sudoku" */ './games/sudoku/views/Sudoku.vue'),
    },
    {
        path: '/sudoku-hard/play/:gameId?',
        name: 'sudoku-hard',
        component: () => import(/* webpackChunkName: "sudoku" */ './games/sudoku/views/Sudoku.vue'),
    },
    {
        path: '/sudoku-expert/play/:gameId?',
        name: 'sudoku-expert',
        component: () => import(/* webpackChunkName: "sudoku" */ './games/sudoku/views/Sudoku.vue'),
    },
    //--- Pyramid
    {
        path: '/pyramid',
        name: 'pyramid',
        component: PyramidIntro,
    },
    {
        path: '/pyramid/play/:gameId?',
        name: 'pyramid-play',
        component: () =>
            import(/* webpackChunkName: "pyramid" */ './games/pyramid/views/Pyramid.vue'),
    },
    // --- Minesweeper
    {
        path: '/minesweeper',
        name: 'minesweeper',
        component: MinesweeperIntro,
    },
    {
        path: '/minesweeper-easy/play/:gameId?',
        name: 'minesweeper-easy',
        component: () =>
            import(/* webpackChunkName: "mine" */ './games/minesweeper/views/Minesweeper.vue'),
    },
    {
        path: '/minesweeper-medium/play/:gameId?',
        name: 'minesweeper-medium',
        component: () =>
            import(/* webpackChunkName: "mine" */ './games/minesweeper/views/Minesweeper.vue'),
    },
    {
        path: '/minesweeper-expert/play/:gameId?',
        name: 'minesweeper-expert',
        component: () =>
            import(/* webpackChunkName: "mine" */ './games/minesweeper/views/Minesweeper.vue'),
    },
    // --- Spider
    {
        path: '/spider',
        name: 'spider',
        component: SpiderIntro,
    },
    {
        path: '/spider4/play/:gameId?',
        name: 'spider-4',
        component: () => import(/* webpackChunkName: "spider" */ './games/spider/views/Spider.vue'),
    },
    {
        path: '/spider2/play/:gameId?',
        name: 'spider-2',
        component: () => import(/* webpackChunkName: "spider" */ './games/spider/views/Spider.vue'),
    },
    {
        path: '/spider1/play/:gameId?',
        name: 'spider-1',
        component: () => import(/* webpackChunkName: "spider" */ './games/spider/views/Spider.vue'),
    },
    // --- TriPeaks
    {
        path: '/tripeaks',
        name: 'tripeaks',
        component: TriPeaksIntro,
    },
    {
        path: '/tripeaks/play/:gameId?',
        name: 'tripeaks-play',
        component: () =>
            import(/* webpackChunkName: "tripeaks" */ './games/tripeaks/views/TriPeaks.vue'),
    },
    // --- Yukon
    {
        path: '/yukon',
        name: 'yukon',
        component: YukonIntro,
    },
    {
        path: '/yukon/play/:gameId?',
        name: 'yukon-play',
        component: () => import(/* webpackChunkName: "yukon" */ './games/yukon/views/Yukon.vue'),
    },
    // --- Forty Thieves
    {
        path: '/forty-thieves',
        name: 'forty',
        component: FortyIntro,
    },
    {
        path: '/forty-thieves/play/:gameId?',
        name: 'forty-play',
        component: () => import(/* webpackChunkName: "forty" */ './games/forty/views/Forty.vue'),
    },
    // --- Scorpion
    {
        path: '/scorpion',
        name: 'scorpion',
        component: ScorpionIntro,
    },
    {
        path: '/scorpion/play/:gameId?',
        name: 'scorpion-play',
        component: () =>
            import(/* webpackChunkName: "scorpion" */ './games/scorpion/views/Scorpion.vue'),
    },
    // --- Canfield
    {
        path: '/canfield',
        name: 'canfield',
        component: CanfieldIntro,
    },
    {
        path: '/canfield1/play/:gameId?',
        name: 'canfield-1',
        component: () =>
            import(/* webpackChunkName: "canfield" */ './games/canfield/views/Canfield.vue'),
    },
    {
        path: '/canfield3/play/:gameId?',
        name: 'canfield-3',
        component: () =>
            import(/* webpackChunkName: "canfield" */ './games/canfield/views/Canfield.vue'),
    },
    // --- Golf
    {
        path: '/golf',
        name: 'golf',
        component: GolfIntro,
    },
    {
        path: '/golf/play/:gameId?',
        name: 'golf-play',
        component: () => import(/* webpackChunkName: "golf" */ './games/golf/views/Golf.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    coreBus.sideMenuToggleCmd$.next(false);
    coreBus.overlayToggleCmd$.next(false);
    coreUtil.exitFullscreen();
    if (to.name == 'klondike-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Klondike');
        appService.setActiveGame(Game.klondike);
    } else if (to.name == 'klondike3-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Klondike (3 Turn)');
        appService.setActiveGame(Game.klondike3);
    } else if (to.name == 'freecell-play') {
        appService.hideFooter();
        appService.setNavbarTitle('FreeCell');
        appService.setActiveGame(Game.freecell);
    } else if (to.name == 'sudoku-easy') {
        appService.hideFooter();
        appService.setNavbarTitle(`Sudoku (easy)`);
        appService.setActiveGame(Game.sudokuEasy);
    } else if (to.name == 'sudoku-medium') {
        appService.hideFooter();
        appService.setNavbarTitle(`Sudoku (medium)`);
        appService.setActiveGame(Game.sudokuMedium);
    } else if (to.name == 'sudoku-hard') {
        appService.hideFooter();
        appService.setNavbarTitle(`Sudoku (hard)`);
        appService.setActiveGame(Game.sudokuHard);
    } else if (to.name == 'sudoku-expert') {
        appService.hideFooter();
        appService.setNavbarTitle(`Sudoku (expert)`);
        appService.setActiveGame(Game.sudokuExpert);
    } else if (to.name == 'pyramid-play') {
        appService.hideFooter();
        appService.setNavbarTitle(`Pyramid`);
        appService.setActiveGame(Game.pyramid);
    } else if (to.name == 'minesweeper-easy') {
        appService.hideFooter();
        appService.setNavbarTitle(`Minesweeper (easy)`);
        appService.setActiveGame(Game.minesweeperEasy);
    } else if (to.name == 'minesweeper-medium') {
        appService.hideFooter();
        appService.setNavbarTitle(`Minesweeper (medium)`);
        appService.setActiveGame(Game.minesweeperMedium);
    } else if (to.name == 'minesweeper-expert') {
        appService.hideFooter();
        appService.setNavbarTitle(`Minesweeper (expert)`);
        appService.setActiveGame(Game.minesweeperExpert);
    } else if (to.name == 'spider-1') {
        appService.hideFooter();
        appService.setNavbarTitle(`Spider (1 suit)`);
        appService.setActiveGame(Game.spider1);
    } else if (to.name == 'spider-2') {
        appService.hideFooter();
        appService.setNavbarTitle(`Spider (2 suits)`);
        appService.setActiveGame(Game.spider2);
    } else if (to.name == 'spider-4') {
        appService.hideFooter();
        appService.setNavbarTitle(`Spider (4 suits)`);
        appService.setActiveGame(Game.spider4);
    } else if (to.name == 'tripeaks-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Tri Peaks');
        appService.setActiveGame(Game.tripeaks);
    } else if (to.name == 'yukon-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Yukon');
        appService.setActiveGame(Game.yukon);
    } else if (to.name == 'forty-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Forty Thieves');
        appService.setActiveGame(Game.fortyThieves);
    } else if (to.name == 'scorpion-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Scorpion');
        appService.setActiveGame(Game.scorpion);
    } else if (to.name == 'canfield-1') {
        appService.hideFooter();
        appService.setNavbarTitle('Canfield');
        appService.setActiveGame(Game.canfield1);
    } else if (to.name == 'canfield-3') {
        appService.hideFooter();
        appService.setNavbarTitle('Canfield (3 Turn)');
        appService.setActiveGame(Game.canfield3);
    } else if (to.name == 'golf-play') {
        appService.hideFooter();
        appService.setNavbarTitle('Golf');
        appService.setActiveGame(Game.golf);
    } else {
        appService.showFooter();
        appService.setNavbarTitle('Solo Games');
        appService.setActiveGame(Game.none);
    }

    if (timerId) {
        clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
        NProgress.start();
    }, 50);

    tracking.pageView(to.path);

    pageMetaService.updateMetaForRoutePath(to.path);

    next();
});

router.afterEach(() => {
    if (timerId) {
        clearTimeout(timerId);
    }
    NProgress.done();
    // to prevent issue that when game load the and the page is scrolled
    // some of the game is hidden behind the top navbar
    window.scrollTo(0, 0);
});

export default router;
