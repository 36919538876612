<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-5 px-5">
        <div class="s-container-2 rounded p-5">
            <h1 class="text-3xl">Settings</h1>
            <div class="m-3">
                <h2 class="mb-1">Display Name</h2>
                <input
                    v-model="name"
                    placeholder="Name..."
                    class="shadow appearance-none border rounded py-2 px-3 mb-1 text-lg text-gray-900 leading-tight bg-gray-200 focus:outline-none focus:shadow-outline"
                />
                <p class="text-gray-400 text-sm">This name will be display in the leader board</p>
            </div>

            <div class="mt-8">
                <s-button @click="save">Save</s-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import SButton from '@/components/SButton.vue';
import { coreLocalStorage } from '@/core/core-local-storage';
import { apiClient } from '@/core/api-client';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    components: {
        SButton,
    },

    setup() {
        const settings = coreLocalStorage.loadPlayerSettings();

        const name = ref(settings.name);

        const save = () => {
            if (name.value != '') {
                coreLocalStorage.updatePlayerSettings({
                    name: name.value,
                });
                apiClient.updatePlayer({
                    name: name.value as string,
                });
                coreBus.showNotificationCmd$.next({
                    type: 'success',
                    text: 'Settings saved',
                });
            }
        };

        return {
            name,
            save,
        };
    },
});
</script>

<style scoped></style>
