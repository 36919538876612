
import { defineComponent, ref } from 'vue';
import ModalDialog from '@/components/ModalDialog.vue';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import { activityLog } from '@/core/activity-log';
import { Background, customize, Pattern } from '@/core/customize';
import { appQuery } from '@/state/app.query';
import { appService } from '@/state/app.service';

export default defineComponent({
    components: {
        ModalDialog,
        SButton,
    },

    setup() {
        const showModal = ref(false);
        const backgrounds = ref<Background[]>([]);
        const patterns = ref<Pattern[]>([]);
        const bgSelected = ref('');
        const patternSelected = ref('');
        const activeTab = ref('background');

        subscribeTo(coreBus.showCustomizeDialogCmd$, () => {
            activityLog.sendActivity('customize-dialog-show');
            showModal.value = true;
            backgrounds.value = customize.getAllBackgrounds();
            patterns.value = customize.getAllPatterns();
            bgSelected.value = appQuery.getValue().customizeOptions.background;
            patternSelected.value = appQuery.getValue().customizeOptions.pattern;
        });

        const handleClose = () => {
            showModal.value = false;
        };

        const getBgStyle = (d: Background) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const style = {} as any;
            if (d.solid) {
                style.background = d.solid;
            }
            if (d.gradient) {
                style.background = d.gradient;
            }
            return style;
        };

        const getPatternStyle = (p: Pattern) => {
            const svg = p.svg.replace('[color]', '%239C92AC');
            return {
                background: `url("${svg}"), #333`,
            };
        };

        const selectBackground = (bg: string) => {
            bgSelected.value = bg;
            const opts = {
                ...appQuery.getValue().customizeOptions,
                background: bg,
            };
            appService.setCustomizeOptions(opts);
            customize.saveOptions(opts);
            activityLog.sendActivity('customize-set-bg', bg);
        };

        const selectPattern = (p: string) => {
            patternSelected.value = p;
            const opts = {
                ...appQuery.getValue().customizeOptions,
                pattern: p,
            };
            appService.setCustomizeOptions(opts);
            customize.saveOptions(opts);
            activityLog.sendActivity('customize-set-pattern', p);
        };

        return {
            showModal,
            handleClose,
            backgrounds,
            getBgStyle,
            bgSelected,
            activeTab,
            selectBackground,
            patterns,
            patternSelected,
            getPatternStyle,
            selectPattern,
        };
    },
});
