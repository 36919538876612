<template>
    <div>
        <modal-dialog v-model="showModal" @on-close="handleClose">
            <div class="p-5">
                <div class="flex mb-3">
                    <div class="text-xl flex-grow">Daily Challenge</div>
                    <div @click="handleClose" class="cursor-pointer">
                        <icon icon="times" :size="6"></icon>
                    </div>
                </div>
                <daily-challenge
                    @start-game="startGame"
                    :init-game="game"
                    :hide-game-selector="true"
                />
            </div>
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { tracking } from '@/core/tracking';
import { coreBus } from '@/core/core-bus';
import { Game } from '@/core/models';
import ModalDialog from '@/components/ModalDialog.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import DailyChallenge from '@/components/DailyChallenge.vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    components: {
        ModalDialog,
        DailyChallenge,
        Icon,
    },

    setup() {
        const showModal = ref(false);
        const game = ref(Game.none);

        subscribeTo(coreBus.showDailyChallengeDialogCmd$, (cmd) => {
            console.log('----showDailyChallengeDialogCmd$');
            showModal.value = true;
            game.value = cmd.game;
            tracking.event('daily-challenge-open-opened', { eventCategory: game.toString() });
        });

        const handleClose = () => {
            showModal.value = false;
        };

        const startGame = (d: { game: Game; gameId: number }) => {
            showModal.value = false;
            coreBus.startGameCmd$.next({
                game: d.game,
                gameId: d.gameId,
            });
        };

        return {
            showModal,
            handleClose,
            game,
            startGame,
        };
    },
});
</script>

<style></style>
