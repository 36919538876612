
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import Icon from '@/components/Icon.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        const show = ref(false);

        subscribeTo(coreBus.sideMenuToggleCmd$, (s) => {
            show.value = s;
        });
        subscribeTo(coreBus.overlayClickedEvent$, () => {
            show.value = false;
        });

        const showCustomizeDialog = () => {
            coreBus.showCustomizeDialogCmd$.next();
            show.value = false;
        };

        return {
            show,
            showCustomizeDialog,
        };
    },
});
