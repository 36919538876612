<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">FreeCell</h1>
            <div class="mt-8">
                <router-link to="/freecell/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play FreeCell</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/freecell" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-10 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The object of the game is to build the four HomeCells up in ascending suit
                    sequence from Ace to King. with cards of identical suit.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    The game is played with a single pack of 52 playing cards. a row of eight cards
                    is dealt face up to start the tableau. A further five rows of eight cards are
                    dealt face up on top of the first to form eight columns of six cards each. The
                    final four cards are dealt to the first four columns so that the first four
                    columns in the tableau each contain seven cards and the last four columns on the
                    right each contain six cards.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    The exposed card at the end of each tableau column is available for play. You
                    may move it to an empty FreeCell at any time to release the card beneath, but
                    it's a good idea to keep the FreeCells empty for as long as possible. As each
                    Ace becomes available it may be transferred to one of the HomeCells. The
                    HomeCells are built up in ascending suit sequence to the King. You may move an
                    exposed card from end of a tableau column or from a FreeCell to another tableau
                    column if it will form a descending sequence of alternating colours, e.g.
                    <span class="text-red-600 font-bold">5♥</span> on
                    <span class="text-black font-bold">6♣</span> or
                    <span class="text-red-600 font-bold">Q♦</span> on
                    <span class="text-black font-bold">K♠</span>. You keep moving single cards, one
                    at a time, until the game either blocks or comes out. Although the rules of
                    FreeCell permit you to move just one card at a time, the empty FreeCells allow
                    you to move an entire sequence of packed cards
                </p>

                <div class="mt-8">
                    <img class="mx-auto" src="/img/freecell-layout.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.freecell);
    },
});
</script>

<style scoped></style>
