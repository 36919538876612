
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    setup() {
        const text = ref('Hello World');
        const show = ref(false);
        let timerId: number | null = null;

        subscribeTo(coreBus.showNotificationCmd$, (cmd) => {
            // if msg already in display close it and show new one
            if (show.value) {
                show.value = false;
            }
            if (timerId) {
                clearTimeout(timerId);
            }

            // show msg
            setTimeout(() => {
                text.value = cmd.text;
                show.value = true;
            });

            // set hide timer
            timerId = setTimeout(() => {
                timerId = null;
                show.value = false;
            }, 2000);
        });

        return {
            text,
            show,
        };
    },
});
