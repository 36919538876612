import { Controller } from '@/core/controller';
import { StatsController } from '@/controllers/stats.controller';

export class Controllers {
    private controllers: Controller[] = [];

    init() {
        this.controllers.push(new StatsController());
    }

    destroy() {
        this.controllers.forEach((c) => c.destroy());
    }
}

export const controllers = new Controllers();
