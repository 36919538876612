/* eslint-disable */
type EventParams = {
    value?: number;
    eventLabel?: string;
    eventCategory?: string;
};

class Tracking {
    private isReady = false;

    private resourceUrl = 'https://www.googletagmanager.com/gtag/js';

    private propertyId = 'UA-176926845-1';

    init() {
        this.loadScript();
    }

    event(action: string, params: EventParams) {
        if (this.isReady) {
            (window as any)['gtag']('event', action, {
                event_category: params.eventCategory,
                event_label: params.eventLabel,
                value: params.value
            });
        }
    }

    pageView(path: string) {
        if (this.isReady) {
            (window as any)['gtag']('config', this.propertyId, {
                page_path: path
            });
        }
    }

    private loadScript() {
        const resource = `${this.resourceUrl}?id=${this.propertyId}`;
        const head = document.head || document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.async = true;
        script.src = resource;
        head.appendChild(script);
        script.onload = () => {
            this.isReady = true;
            (window as any)['dataLayer'] = (window as any).dataLayer || [];
            (window as any)['gtag'] = function() {
                (window as any)['dataLayer'].push(arguments);
            };
            (window as any)['gtag']('js', new Date());
            (window as any)['gtag']('config', this.propertyId);
        };
    }
}

export const tracking = new Tracking();
