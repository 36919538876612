import { Store, StoreConfig } from '@datorama/akita';
import { CustomizeOptions, Game } from '@/core/models';

export interface AppState {
    showFooter: boolean;
    navbarTitle: string;
    activeGame: Game;
    isAppHidden: boolean;
    disablePageScroll: boolean;
    customizeOptions: CustomizeOptions;
}

const initialState = {
    showFooter: true,
    navbarTitle: 'Solo Games',
    activeGame: Game.none,
    isAppHidden: false,
    disablePageScroll: false,
    customizeOptions: {
        background: 'blue',
        pattern: 'roundedPlus',
    },
} as AppState;

@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
    constructor() {
        super(initialState);
    }
}

export const appStore = new AppStore();
