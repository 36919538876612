<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Canfeild</h1>
            <div class="mt-8">
                <router-link to="/canfield1/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3"
                        >Play Canfield (Turn One)</span
                    ></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/canfield3/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3"
                        >Play Canfield (Turn Three)</span
                    ></router-link
                >
            </div>
            <!--div class="mt-10">
            <router-link
                to="/daily-challenge/klondike"
                class="rounded-md text-xl py-3 border border-green-600 shadow-gl px-5 bg-green-700 hover:bg-green-600 focus:outline-none"
            >
                <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
            >
        </div-->
            <div class="mt-10 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    On foundation cards, build an ascending sequence for each of the four suits. You
                    may build your sequences "round-the-corner"; that is, the ace can follow the
                    king and the two can follow the ace. Essentially, you have to stack the four
                    suits in ascending order.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    A single card is dealt to the foundation of the same suit: this card forms the
                    lead rank. 13 cards are dealt to the reserve pile, with the top card facing up.
                    1 card is dealt into each tableau. The remaining cards are placed into the stock
                    face down. The waste and the other three foundations start off empty.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    The top card from the reserve is always available for play on a foundation or a
                    pile in the tableau. The top card of each tableau pile is always available to be
                    played onto a foundation. Cards from the draw pile are turned face up (three at
                    a time or one depend on the version you play), as you decide to do so, forming a
                    discard pile. The top card of the discard pile is always available for play.
                    Cards below it may not be played until the top card is played. To move cards
                    from one tableau pile to another, you may only move the entire pile. When space
                    is available in the tableau, immediately use the top card from the reserve to
                    fill that space. If the reserve is exhausted, a space in the tableau may be
                    filled from the top of the discard pile. In this situation, you may leave the
                    space open for as long as you wish. When the draw pile is exhausted, pick up the
                    discard pile and turn it face down, without shuffling it. This becomes a new
                    draw pile. You may do this as many times as you wish until you win or until the
                    game reaches a standstill.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.klondike);
        apiClient.getWinnableGames(Game.klondike3);
    },
});
</script>

<style scoped></style>
