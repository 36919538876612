<template>
    <transition name="anim-fade">
        <div v-if="show" class="overlay" @click="close"></div>
    </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    setup() {
        const show = ref(false);

        subscribeTo(coreBus.overlayToggleCmd$, (s) => {
            show.value = s;
        });

        const close = () => {
            coreBus.overlayClickedEvent$.next();
            show.value = false;
        };

        return {
            show,
            close,
        };
    },
});
</script>

<style scoped>
.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    right: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
</style>
