import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { akitaDevtools } from '@datorama/akita';
import axios from 'axios';
import { coreLocalStorage } from '@/core/core-local-storage';
import { tracking } from '@/core/tracking';

import './assets/tailwind.css';
import 'nprogress/nprogress.css';

// akita
if (process.env.NODE_ENV === 'development') {
    akitaDevtools();
}

// inject fingerprint
axios.interceptors.request.use(
    async (config) => {
        const fp = await coreLocalStorage.getOrCreateFingerprint();
        config.headers['x-fingerprint'] = fp;
        return config;
    },
    () => {
        return Promise.reject();
    }
);

// gtag
if (process.env.NODE_ENV !== 'development') {
    tracking.init();
}

createApp(App).use(router).mount('#app');
