<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Forty Thieves</h1>
            <div class="mt-8">
                <router-link to="/forty-thieves/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Forty Thieves</span></router-link
                >
            </div>
            <!--div class="mt-10">
            <router-link
                to="/daily-challenge/freecell"
                class="rounded-md text-xl py-3 border border-green-600 shadow-gl px-5 bg-green-700 hover:bg-green-600 focus:outline-none"
            >
                <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
            >
        </div-->
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The goal of Forty Thieves is to build eight foundations up from Ace to King,
                    keeping the suit.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    Deal 40 cards to the tableau in 10 columns of four cards each. The cards in each
                    column should overlap so that all 10 cards are visible. The remaining cards are
                    set aside in a single face-down pile. This is the stock.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    The rules are very simple. You are only allowed to move cards one by one. When
                    you move a card it must be in placed in descending order and in the same suit.
                    For example you can move a 10 of clubs only onto a Jack of clubs. When a column
                    is empty you can move any single card onto it to start it off. You can turn over
                    a single card at a time and put them whenever you decide you want to or if
                    course if there are no possible moves for you. You are only allowed to pass
                    through the waste pile once and of course you can only use the top card of the
                    waste pile.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.yukon);
    },
});
</script>

<style scoped></style>
