<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Scorpion</h1>
            <div class="mt-8">
                <router-link to="/scorpion/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Scorpion</span></router-link
                >
            </div>
            <!--div class="mt-10">
            <router-link
                to="/daily-challenge/freecell"
                class="rounded-md text-xl py-3 border border-green-600 shadow-gl px-5 bg-green-700 hover:bg-green-600 focus:outline-none"
            >
                <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
            >
        </div-->
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    To build four sequences down in suit within the tableau, each running from King
                    to Ace.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    One deck (52 cards). 7 tableau piles. Three rows of 7 cards are dealt - the
                    first 4 cards face down and the next 3 face up in each row. Then four more rows
                    of 7 cards are dealt, all face up. The remaining 3 cards are put face down as a
                    reserve.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    You may build tableau piles down in suit. Groups of cards can be moved
                    regardless of any sequence. Any face up card in a tableau can be moved to make a
                    build. All the cards covering it are moved together as a unit. If, during play,
                    any closed cards become the top card of a pile it turns face up.
                </p>
                <p>Empty tableaus may be filled with a King or group of cards headed by a King.</p>
                <p>
                    When you have made all the moves initially available, click on the reserve pile
                    to deal the 3 remaining cards face up to the three leftmost piles.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.scorpion);
        console.log('111111');
    },
});
</script>

<style scoped></style>
