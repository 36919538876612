<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 px-1">
        <div class="s-container-2 rounded p-5">
            <h1 class="text-3xl">Statistics</h1>
            <div class="my-3">
                <table v-show="!state.selected">
                    <thead>
                        <tr>
                            <th>Game Name</th>
                            <th>Game Played</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in state.topStats" :key="item.game">
                            <td>
                                <button
                                    @click="showGameStats(item.game)"
                                    class="s-button w-full blue py-2 px-4"
                                >
                                    {{ item.name }}
                                </button>
                            </td>
                            <td>{{ item.count }}</td>
                        </tr>
                    </tbody>
                </table>

                <div v-show="state.selected">
                    <button @click="state.selected = null" class="s-button w-full blue py-2 px-4">
                        back
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { coreUtil } from '@/core/core-util';
import { Stats } from '@/core/stats';
import { Game } from '@/core/models';
import { orderBy } from 'lodash';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    setup() {
        const gameStats = coreUtil
            .getAllGames()
            .filter((g) => !!localStorage.getItem(`${g}.stats`))
            .map((g) => new Stats(g));

        const topStats = gameStats.map((s) => ({
            game: s.game,
            name: coreUtil.gameDisplayName(s.game),
            count: s.load().gamesStarted,
        }));

        const showGameStats = (game: Game) => {
            const hide =
                [
                    Game.minesweeperEasy,
                    Game.minesweeperMedium,
                    Game.minesweeperExpert,
                    Game.sudokuEasy,
                    Game.sudokuMedium,
                    Game.sudokuHard,
                    Game.sudokuExpert,
                ].indexOf(game) >= 0;
            coreBus.showGameStatisticDialogCmd$.next({
                game,
                hideMoveData: hide ? true : undefined,
                hideScoreData: hide ? true : undefined,
            });
        };

        const selected: Game | null = null;

        const state = reactive({
            topStats: orderBy(topStats, (x) => x.count, 'desc'),
            selected,
        });

        return {
            state,
            showGameStats,
        };
    },
});
</script>

<style scoped>
table {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border: thin solid hsla(0, 0%, 100%, 0.12);
}
table tr td {
    border-left: thin solid hsla(0, 0%, 100%, 0.12);
    border-right: thin solid hsla(0, 0%, 100%, 0.12);
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
    padding: 0.3rem;
    font-size: 0.9rem;
    text-align: center;
}
table tr th {
    background-color: hsla(0, 0%, 100%, 0.12);
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 0.8rem;
    text-align: center;
    color: #aaa;
}
</style>
