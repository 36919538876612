
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },
    setup() {
        apiClient.getWinnableGames(Game.sudokuEasy);
        apiClient.getWinnableGames(Game.sudokuMedium);
        apiClient.getWinnableGames(Game.sudokuHard);
        apiClient.getWinnableGames(Game.sudokuExpert);
    },
});
