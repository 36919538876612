
import { defineComponent, ref } from 'vue';
import SButton from '@/components/SButton.vue';
import { coreLocalStorage } from '@/core/core-local-storage';
import { apiClient } from '@/core/api-client';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    components: {
        SButton,
    },

    setup() {
        const settings = coreLocalStorage.loadPlayerSettings();

        const name = ref(settings.name);

        const save = () => {
            if (name.value != '') {
                coreLocalStorage.updatePlayerSettings({
                    name: name.value,
                });
                apiClient.updatePlayer({
                    name: name.value as string,
                });
                coreBus.showNotificationCmd$.next({
                    type: 'success',
                    text: 'Settings saved',
                });
            }
        };

        return {
            name,
            save,
        };
    },
});
