
import { defineComponent, ref } from 'vue';
import Leaderboard from '@/components/Leaderboard.vue';
import { Game, Score } from '@/core/models';
import { tracking } from '@/core/tracking';
import { apiClient } from '@/core/api-client';
import { coreBus } from '@/core/core-bus';
import ModalDialog from '@/components/ModalDialog.vue';
import SButton from '@/components/SButton.vue';
import Icon from '@/components/Icon.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        Icon,
        SButton,
        ModalDialog,
        Leaderboard,
    },

    setup() {
        const showModal = ref(false);
        const leaderboard = ref<Score[]>([]);
        const gameId = ref(0);
        const game = ref(Game.none);

        const loadData = async () => {
            leaderboard.value = await apiClient.getLeaderBoard(game.value.toString(), gameId.value);
        };

        subscribeTo(coreBus.showLeaderBoardDialogCmd$, (cmd) => {
            gameId.value = cmd.gameId;
            game.value = cmd.game;
            showModal.value = true;
            loadData();
            tracking.event('leader-board-opened', { eventCategory: game.toString() });
        });

        const challengeFriend = () => {
            showModal.value = false;
            coreBus.showChallengeFriendDialogCmd$.next({
                game: game.value,
                gameId: gameId.value,
            });
        };

        const goToDailyChallenge = () => {
            showModal.value = false;
            coreBus.showDailyChallengeDialogCmd$.next({
                game: game.value,
                gameId: gameId.value,
            });
        };

        const handleClose = () => {
            showModal.value = false;
        };

        return {
            showModal,
            leaderboard,
            gameId,
            game,
            challengeFriend,
            goToDailyChallenge,
            handleClose,
        };
    },
});
