<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Tri Peaks</h1>
            <div class="mt-8">
                <router-link to="/tripeaks/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Tri Peaks</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/tripeaks" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The object of Tri-Peaks is to transfer all the cards from the tableau to the
                    waste pile, uncovering cards further up the peaks, making them available for
                    play. You win the game when you've removed all the cards from the tableau to the
                    waste pile, demolishing the three peaks.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    The game is played with a single pack of 52 playing cards. The cards are dealt
                    to form a tableau consisting of three overlapping peaks in the arrangement shown
                    below. A single card is then dealt face up from the remaining stock to start a
                    waste pile that is built on during the game.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    All building is carried out on the single waste pile. Any completely uncovered
                    card in the tableau may be moved to the waste pile if it follows either an
                    ascending or descending sequence regardless of suit. Sequences may turn the
                    corner with Kings building on Aces and Aces building on Kings. When no more
                    cards can be transferred from the tableau to the waste pile, a card from the
                    stock is dealt to the waste pile and building resumes. When the stock is
                    exhausted, and no more building can occur, then the game is over.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.tripeaks);
    },
});
</script>

<style scoped></style>
