import { Game } from '@/core/models';
import { apiClient } from '@/core/api-client';

export class ActivityLog {
    sendGameActivity(
        game: Game,
        gameId: number,
        activityName: string,
        activityInfo?: string | undefined
    ) {
        apiClient.addActivity({
            gameId,
            gameName: game.toString(),
            activityName,
            activityInfo,
        });
    }

    sendActivity(activityName: string, activityInfo?: string | undefined) {
        apiClient.addActivity({
            gameId: undefined,
            gameName: undefined,
            activityName,
            activityInfo,
        });
    }
}

export const activityLog = new ActivityLog();
