<template>
    <div
        v-show="show"
        class="flex items-center justify-center h-screen fixed w-full"
        style="z-index: 200"
    >
        <div class="pause-box text-center p-10 rounded-md shadow-lg" style="opacity: 0.99">
            <div
                class="p-6 w-40 border border-yellow-500 rounded mb-8 text-4xl text-yellow-500 bg-yellow-400 bg-opacity-25"
            >
                Paused
            </div>
            <s-button color="blue" @click="resume"> Resume </s-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: { SButton },

    setup() {
        const show = ref(false);

        subscribeTo(coreBus.showPauseOverlay$, () => {
            show.value = true;
            coreBus.overlayToggleCmd$.next(true);
        });

        const resume = () => {
            show.value = false;
            coreBus.resumeClickedEvent$.next();
            coreBus.overlayToggleCmd$.next(false);
        };

        return {
            show,
            resume,
        };
    },
});
</script>

<style scoped>
.pause-box {
    background-color: rgba(30, 30, 30, 0.9);
}
</style>
