import { coreLocalStorage } from '@/core/core-local-storage';
import { Game, StatisticItem } from '@/core/models';
import { GameMetrics } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import { ShowGameStatisticDialogCmd } from '@/core/core-bus';

export type StatData = {
    version: number;
    startTime: number;
    // count
    gamesStarted: number;
    gamesWon: number;
    // time
    shortestWinningTime: number;
    longestWinningTime: number;
    totalWinningTime: number;
    // moves
    fewestWinningMoves: number;
    mostWinningMoves: number;
    totalWinningMoves: number;
    // undo
    winsWithoutUndo: number;
    // score
    highWinningScore: number;
    totalWinningScore: number;
    // streak
    currentWinningStreak: number;
    longestWinningStreak: number;
};

const initData = {
    version: 1,
    startTime: 0,
    gamesStarted: 0,
    gamesWon: 0,
    shortestWinningTime: 0,
    longestWinningTime: 0,
    totalWinningTime: 0,
    fewestWinningMoves: 0,
    mostWinningMoves: 0,
    totalWinningMoves: 0,
    winsWithoutUndo: 0,
    highWinningScore: 0,
    totalWinningScore: 0,
    currentWinningStreak: 0,
    longestWinningStreak: 0,
};

export class Stats {
    readonly game: Game;

    private readonly key: string;

    constructor(game: Game) {
        this.game = game;
        this.key = `${game.toString()}.stats`;
    }

    updateGameStart() {
        const data = this.load();
        data.gamesStarted += 1;
        //  if prev game is not completed
        const resetStreak = coreLocalStorage.isGameInProgress(Game.freecell);
        if (resetStreak) {
            data.currentWinningStreak = 0;
        }
        this.save(data);
    }

    updateGameWin(metrics: GameMetrics) {
        const data = this.load();

        // win
        data.gamesWon += 1;

        // time
        data.totalWinningTime += metrics.time;
        if (data.shortestWinningTime == 0 || metrics.time < data.shortestWinningTime) {
            data.shortestWinningTime = metrics.time;
        }
        if (data.longestWinningTime == 0 || metrics.time > data.longestWinningTime) {
            data.longestWinningTime = metrics.time;
        }

        // moves
        data.totalWinningMoves += metrics.moves;
        if (data.fewestWinningMoves == 0 || metrics.moves < data.fewestWinningMoves) {
            data.fewestWinningMoves = metrics.moves;
        }
        if (data.mostWinningMoves == 0 || metrics.moves > data.mostWinningMoves) {
            data.mostWinningMoves = metrics.moves;
        }

        // undo
        if (metrics.undoCount == 0) {
            data.winsWithoutUndo += 1;
        }

        // score
        data.totalWinningScore += metrics.score;
        if (data.highWinningScore == 0 || metrics.score > data.highWinningScore) {
            data.highWinningScore = metrics.score;
        }

        // streak
        data.currentWinningStreak += 1;
        if (data.currentWinningStreak > data.longestWinningStreak) {
            data.longestWinningStreak = data.currentWinningStreak;
        }

        this.save(data);
    }

    getStartTime(): number {
        const data = this.load();
        return data.startTime;
    }

    getStatsItems(cmd: ShowGameStatisticDialogCmd): StatisticItem[] {
        const data = this.load();
        const items: StatisticItem[] = [];

        items.push({
            label: 'Games Won',
            content: `${data.gamesWon}`,
            percent: this.toPercent(data.gamesWon, data.gamesStarted),
        });
        items.push({
            label: 'Games Lost',
            content: `${data.gamesStarted - data.gamesWon}`,
            percent: this.toPercent(data.gamesStarted - data.gamesWon, data.gamesStarted),
        });
        items.push({
            label: 'Shortest Winning Time',
            content: this.toTime(data.shortestWinningTime),
        });
        items.push({
            label: 'Longest Winning Time',
            content: this.toTime(data.longestWinningTime),
        });
        items.push({
            label: 'Average Winning Time',
            content:
                data.gamesWon > 0
                    ? this.toTime(Math.floor(data.totalWinningTime / data.gamesWon))
                    : '',
        });

        if (cmd.hideMoveData == undefined) {
            items.push({
                label: 'Fewest Winning Moves',
                content: this.toString(data.fewestWinningMoves),
            });
            items.push({
                label: 'Most Winning Moves',
                content: this.toString(data.mostWinningMoves),
            });
            items.push({
                label: 'Average Winning Moves',
                content:
                    data.gamesWon > 0 ? (data.totalWinningMoves / data.gamesWon).toFixed(0) : '',
            });
            items.push({
                label: 'Wind Without Undo',
                content: this.toString(data.winsWithoutUndo),
            });
        }

        if (cmd.hideScoreData == undefined) {
            items.push({
                label: 'High Score',
                content: this.toString(data.highWinningScore),
            });
            items.push({
                label: 'Average Winning Score',
                content:
                    data.gamesWon > 0 ? (data.totalWinningScore / data.gamesWon).toFixed(0) : '',
            });
        }

        items.push({
            label: 'Current Winning Streak',
            content: this.toString(data.currentWinningStreak),
        });
        items.push({
            label: 'Longest Winning Streak',
            content: this.toString(data.longestWinningStreak),
        });

        return items;
    }

    load(): StatData {
        const str = localStorage.getItem(this.key);
        if (str) {
            try {
                return JSON.parse(str);
            } catch {
                return { ...initData, startTime: new Date().getTime() };
            }
        }
        return { ...initData, startTime: new Date().getTime() };
    }

    clearStats() {
        localStorage.removeItem(this.key);
    }

    private save(data: StatData) {
        const str = JSON.stringify(data);
        localStorage.setItem(this.key, str);
    }

    protected toPercent(numerator: number, denominator: number) {
        if (denominator === 0) {
            return 0;
        }
        return Math.round((numerator / denominator) * 100);
    }

    protected toTime(time: number) {
        return coreUtil.timeFormat(time);
    }

    // eslint-disable-next-line
    private toString(val: any) {
        if (val) {
            return val.toString();
        }
        return '';
    }
}
