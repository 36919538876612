<template>
    <div>
        <modal-dialog v-model="state.showModal" @on-close="handleClose">
            <div class="p-5">
                <div class="flex">
                    <div class="text-xl flex-grow">Challenge Friend</div>
                    <div @click="handleClose" class="cursor-pointer">
                        <icon icon="times" :size="6"></icon>
                    </div>
                </div>
                <div class="mt-6">
                    <div>Challenge a friend to beat your score for game: #{{ state.gameId }}</div>
                    <div style="margin-top: 2rem">
                        <div v-if="isMobileShare">
                            <s-button color="blue" @click="share()">Invite Friend</s-button>
                        </div>
                        <div v-if="!isMobileShare">
                            <s-button color="blue" :href="mailtoUrl">Email</s-button>
                        </div>
                    </div>
                    <div style="margin-top: 3rem">
                        Or copy the link bellow and send to your friend
                    </div>
                    <div class="url-box">
                        {{ url }}
                    </div>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from 'vue';
import { tracking } from '@/core/tracking';
import { coreBus } from '@/core/core-bus';
import { Game } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    components: { SButton, Icon, ModalDialog },

    setup() {
        const state = reactive({
            showModal: false,
            gameId: 0,
            displayName: '',
        });
        let game = Game.none;

        subscribeTo(coreBus.showChallengeFriendDialogCmd$, (cmd) => {
            state.showModal = true;
            game = cmd.game;
            state.gameId = cmd.gameId;
            state.displayName = coreUtil.gameDisplayName(cmd.game);
            tracking.event('challenge-friend-opened', { eventCategory: game.toString() });
            activityLog.sendGameActivity(game, cmd.gameId, 'challenge-friend-opened');
        });

        const handleClose = () => {
            state.showModal = false;
        };

        const isMobileShare = computed(() => {
            return !!navigator.share;
        });

        const url = computed(() => {
            const abrv = coreUtil.getGameAbbreviation(game);
            return `${window.location.origin}/r/${abrv}-${state.gameId}`;
        });

        const mailtoUrl = computed(() => {
            const subject = 'Solo Games Invitation';
            const body = `Hello, Your friend challenge you to a game of ${state.displayName}.%0A%0AClick on the link bellow to play.%0A%0A${url.value}%0A%0A%0A%0A`;
            return `mailto:?subject=${subject}&body=${body}`;
        });

        const share = () => {
            tracking.event('challenge-friend-share-clicked', { eventCategory: game.toString() });
            if (navigator.share) {
                navigator
                    .share({
                        title: 'Solo Games',
                        text: `Hello, Your friend challenge you to a game of ${state.displayName}`,
                        url: url.value,
                    })
                    .then(() => console.log('Successfully shared'))
                    .catch((error) => console.log('Error sharing:', error));
            }
        };

        return {
            state,
            handleClose,
            isMobileShare,
            mailtoUrl,
            url,
            share,
        };
    },
});
</script>

<style scoped>
.url-box {
    background-color: #333;
    color: #aaa;
    border: #777 1px solid;
    padding: 0.5rem;
    margin-top: 0.5rem;
}
</style>
