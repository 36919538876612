
import { defineComponent, reactive } from 'vue';
import { coreUtil } from '@/core/core-util';
import { Stats } from '@/core/stats';
import { Game } from '@/core/models';
import { orderBy } from 'lodash';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    setup() {
        const gameStats = coreUtil
            .getAllGames()
            .filter((g) => !!localStorage.getItem(`${g}.stats`))
            .map((g) => new Stats(g));

        const topStats = gameStats.map((s) => ({
            game: s.game,
            name: coreUtil.gameDisplayName(s.game),
            count: s.load().gamesStarted,
        }));

        const showGameStats = (game: Game) => {
            const hide =
                [
                    Game.minesweeperEasy,
                    Game.minesweeperMedium,
                    Game.minesweeperExpert,
                    Game.sudokuEasy,
                    Game.sudokuMedium,
                    Game.sudokuHard,
                    Game.sudokuExpert,
                ].indexOf(game) >= 0;
            coreBus.showGameStatisticDialogCmd$.next({
                game,
                hideMoveData: hide ? true : undefined,
                hideScoreData: hide ? true : undefined,
            });
        };

        const selected: Game | null = null;

        const state = reactive({
            topStats: orderBy(topStats, (x) => x.count, 'desc'),
            selected,
        });

        return {
            state,
            showGameStats,
        };
    },
});
