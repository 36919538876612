<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Yukon</h1>
            <div class="mt-8">
                <router-link to="/yukon/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Yukon</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/yukon" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The object of the game is to build the four foundations up in ascending suit
                    sequence from Ace to King with cards of identical suit.
                </p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    The game is played with a single pack of 52 playing cards. After shuffling the
                    deck, 28 cards are dealt in exactly the same way as Klondike to start the
                    tableau which consists of 7 columns. The remaining 24 cards are dealt face up to
                    the final 6 columns. The game then begins.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    Any face up card, no matter how deep it is in a column, may be moved to another
                    column if it creates a descending sequence of alternating colours. All the
                    covering cards are moved with it as a unit and don't have to be in sequence. As
                    long as the join between the two columns makes a descending sequence of
                    alternating colours then the move is valid. As each Ace becomes available it may
                    be transferred to one of the four foundations. The foundations are built up in
                    ascending suit sequence to the King. Empty tableau columns can only be filled by
                    a King or a group of cards headed by a King. As soon as a face down card is
                    exposed, it is turned over making it available for play.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.yukon);
    },
});
</script>

<style scoped></style>
