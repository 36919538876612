<template>
    <modal-dialog v-model="showModal" @on-close="handleClose">
        <div class="p-5">
            <div class="flex">
                <div class="text-xl flex-grow">Customize</div>
                <s-button @click="handleClose">Done</s-button>
            </div>
            <div class="mt-6">
                <ul class="tabs flex border-b">
                    <li
                        class="tab"
                        @click="activeTab = 'background'"
                        :class="{ active: activeTab === 'background' }"
                    >
                        Background
                    </li>
                    <li
                        class="tab"
                        @click="activeTab = 'pattern'"
                        :class="{ active: activeTab === 'pattern' }"
                    >
                        Pattern
                    </li>
                </ul>
            </div>
            <div class="mt-6" v-show="activeTab === 'background'">
                <div
                    v-for="bg in backgrounds"
                    :key="bg.name"
                    class="bg-box"
                    :class="{ selected: bg.name === bgSelected }"
                    :style="getBgStyle(bg)"
                    @click="selectBackground(bg.name)"
                ></div>
            </div>
            <div class="mt-6" v-show="activeTab === 'pattern'">
                <div
                    v-for="p in patterns"
                    :key="p.name"
                    class="pattern-box"
                    :class="{ selected: p.name === patternSelected }"
                    :style="getPatternStyle(p)"
                    @click="selectPattern(p.name)"
                ></div>
            </div>
        </div>
    </modal-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ModalDialog from '@/components/ModalDialog.vue';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';
import { activityLog } from '@/core/activity-log';
import { Background, customize, Pattern } from '@/core/customize';
import { appQuery } from '@/state/app.query';
import { appService } from '@/state/app.service';

export default defineComponent({
    components: {
        ModalDialog,
        SButton,
    },

    setup() {
        const showModal = ref(false);
        const backgrounds = ref<Background[]>([]);
        const patterns = ref<Pattern[]>([]);
        const bgSelected = ref('');
        const patternSelected = ref('');
        const activeTab = ref('background');

        subscribeTo(coreBus.showCustomizeDialogCmd$, () => {
            activityLog.sendActivity('customize-dialog-show');
            showModal.value = true;
            backgrounds.value = customize.getAllBackgrounds();
            patterns.value = customize.getAllPatterns();
            bgSelected.value = appQuery.getValue().customizeOptions.background;
            patternSelected.value = appQuery.getValue().customizeOptions.pattern;
        });

        const handleClose = () => {
            showModal.value = false;
        };

        const getBgStyle = (d: Background) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const style = {} as any;
            if (d.solid) {
                style.background = d.solid;
            }
            if (d.gradient) {
                style.background = d.gradient;
            }
            return style;
        };

        const getPatternStyle = (p: Pattern) => {
            const svg = p.svg.replace('[color]', '%239C92AC');
            return {
                background: `url("${svg}"), #333`,
            };
        };

        const selectBackground = (bg: string) => {
            bgSelected.value = bg;
            const opts = {
                ...appQuery.getValue().customizeOptions,
                background: bg,
            };
            appService.setCustomizeOptions(opts);
            customize.saveOptions(opts);
            activityLog.sendActivity('customize-set-bg', bg);
        };

        const selectPattern = (p: string) => {
            patternSelected.value = p;
            const opts = {
                ...appQuery.getValue().customizeOptions,
                pattern: p,
            };
            appService.setCustomizeOptions(opts);
            customize.saveOptions(opts);
            activityLog.sendActivity('customize-set-pattern', p);
        };

        return {
            showModal,
            handleClose,
            backgrounds,
            getBgStyle,
            bgSelected,
            activeTab,
            selectBackground,
            patterns,
            patternSelected,
            getPatternStyle,
            selectPattern,
        };
    },
});
</script>

<style scoped>
.bg-box {
    width: 100px;
    height: 100px;
    background: rgb(47, 70, 185);
    background: radial-gradient(circle, rgb(111, 133, 248) 0%, rgba(47, 70, 185, 1) 85%);
    display: inline-block;
    margin: 0.8rem;
    border: #777 2px solid;
    border-radius: 6px;
    cursor: pointer;
}
.bg-box:hover {
    border-color: white;
}
.bg-box.selected {
    border-color: white;
}
.pattern-box {
    width: 100%;
    height: 70px;
    margin-top: 1rem;
    border-radius: 3px;
    border: #666 1px solid;
    cursor: pointer;
}
.pattern-box.selected {
    border-color: white;
}

.tabs .tab {
    @apply py-2;
    @apply px-4;
    @apply text-white;
    display: inline-block;
    cursor: pointer;
}
.tabs .tab.active {
    @apply mr-1;
    @apply border-t;
    @apply border-r;
    @apply border-l;
    @apply rounded-t;
    @apply bg-gray-800;
    margin-bottom: -1px;
}
</style>
