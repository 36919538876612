
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    setup() {
        const show = ref(false);

        subscribeTo(coreBus.overlayToggleCmd$, (s) => {
            show.value = s;
        });

        const close = () => {
            coreBus.overlayClickedEvent$.next();
            show.value = false;
        };

        return {
            show,
            close,
        };
    },
});
