
import { defineComponent } from 'vue';
import { coreBus } from '@/core/core-bus';
import Icon from '@/components/Icon.vue';
import { appQuery } from '@/state/app.query';
import { useObservable } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        const title = useObservable(appQuery.navbarTitle$, 'Solo Games');

        const showMenu = () => {
            coreBus.sideMenuToggleCmd$.next(true);
            coreBus.overlayToggleCmd$.next(true);
        };

        const showGamesNav = (e: MouseEvent) => {
            e.stopPropagation();
            coreBus.toggleGamesNavCmd$.next();
        };

        return {
            title,
            showMenu,
            showGamesNav,
        };
    },
});
