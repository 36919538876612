<template>
    <div class="sidebar">
        <transition name="anim-slide-left">
            <div v-show="show" class="sidebar-panel">
                <div class="border-b border-gray-700 p-5">
                    <h1 class="text-xl">Solo Games</h1>
                </div>
                <div class="border-gray-700 text-white">
                    <router-link to="/" class="flex flex-row p-3">
                        <div><icon icon="th"></icon></div>
                        <div class="ml-6">Home</div>
                    </router-link>
                    <router-link to="/game-history" class="flex flex-row p-3">
                        <div><icon icon="history"></icon></div>
                        <div class="ml-6">Game History</div>
                    </router-link>
                    <router-link to="/settings" class="flex flex-row p-3">
                        <div><icon icon="cog"></icon></div>
                        <div class="ml-6">Settings</div>
                    </router-link>
                    <router-link to="/daily-challenge" class="flex flex-row p-3">
                        <div><icon icon="calendar"></icon></div>
                        <div class="ml-6">Daily Challenge</div>
                    </router-link>
                    <a @click="showCustomizeDialog" class="flex flex-row p-3 cursor-pointer">
                        <div><icon icon="palette"></icon></div>
                        <div class="ml-6">Customize</div>
                    </a>
                    <router-link to="/statistics" class="flex flex-row p-3">
                        <div><icon icon="chart"></icon></div>
                        <div class="ml-6">Statistics</div>
                    </router-link>
                    <router-link to="/contact" class="flex flex-row p-3">
                        <div><icon icon="envelope"></icon></div>
                        <div class="ml-6">Contact</div>
                    </router-link>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import Icon from '@/components/Icon.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        const show = ref(false);

        subscribeTo(coreBus.sideMenuToggleCmd$, (s) => {
            show.value = s;
        });
        subscribeTo(coreBus.overlayClickedEvent$, () => {
            show.value = false;
        });

        const showCustomizeDialog = () => {
            coreBus.showCustomizeDialogCmd$.next();
            show.value = false;
        };

        return {
            show,
            showCustomizeDialog,
        };
    },
});
</script>

<style>
.sidebar-panel {
    overflow-y: auto;
    background-color: #363636;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    width: 260px;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-right: 1px solid #777;
}
a:hover {
    @apply bg-gray-700;
}
</style>
