import { Controller } from '@/core/controller';
import { coreBus, StatsEvent, StatsEventType } from '@/core/core-bus';
import { Stats } from '@/core/stats';

export class StatsController extends Controller {
    constructor() {
        super();
        this.subscribeTo(coreBus.statsEvent$, (ev) => {
            if (ev.type == StatsEventType.gameStated) {
                this.handleGameStarted(ev);
            } else if (ev.type == StatsEventType.gameWon) {
                this.handleGameWon(ev);
            }
        });
    }

    private handleGameStarted(ev: StatsEvent) {
        console.log(ev);
        const stats = new Stats(ev.game);
        stats.updateGameStart();
    }

    private handleGameWon(ev: StatsEvent) {
        console.log(ev);
        if (ev.metrics) {
            const stats = new Stats(ev.game);
            stats.updateGameWin(ev.metrics);
        }
    }
}
