export type Size = {
    width: number;
    height: number;
};

export type Position = {
    x: number;
    y: number;
};

export enum Suit {
    Heart = 'H',
    Diamond = 'D',
    Club = 'C',
    Spade = 'S',
}

export type Score = {
    name: string;
    score: number;
    moves: number;
    time: number;
    createdAt: string;
    isMyScore: boolean;
};

export type PlayerSettings = {
    name: string | null;
    mute: boolean;
};

export enum Game {
    none = 'none',
    klondike = 'klondike',
    klondike3 = 'klondike3',
    freecell = 'freecell',
    sudokuEasy = 'sudoku-easy',
    sudokuMedium = 'sudoku-medium',
    sudokuHard = 'sudoku-hard',
    sudokuExpert = 'sudoku-expert',
    pyramid = 'pyramid',
    minesweeperEasy = 'minesweeper-easy',
    minesweeperMedium = 'minesweeper-medium',
    minesweeperExpert = 'minesweeper-expert',
    spider1 = 'spider1',
    spider2 = 'spider2',
    spider4 = 'spider4',
    tripeaks = 'tripeaks',
    yukon = 'yukon',
    fortyThieves = 'forty-thieves',
    scorpion = 'scorpion',
    canfield1 = 'canfield1',
    canfield3 = 'canfield3',
    golf = 'golf',
}

export type GameHistoryItem = {
    game: Game;
    gameId: number;
    date: Date;
    moves: number;
    time: number;
    score: number;
};

export type StatisticItem = {
    label: string;
    content: string;
    percent?: number;
};

export type GameMetrics = {
    time: number;
    moves: number;
    score: number;
    undoCount: number;
    hintCount: number;
};

export enum GameStatus {
    none = 'none',
    readyToDeal = 'readyToDeal',
    deal = 'deal',
    dealCompleted = 'dealCompleted',
    running = 'running',
    paused = 'paused',
    gameCompleted = 'gameCompleted',
    gameFailed = 'gameFailed',
}

export enum CardOwner {
    none = 'none',
    stock = 'stock',
    tableau = 'tableau',
    foundation = 'foundation',
    freeCell = 'freeCell',
    peaks = 'peaks',
    discard = 'discard',
    pyramid = 'pyramid',
    waste = 'waste',
    reserve = 'reserve',
}

export type Card = {
    id: string;
    name?: string | undefined;
    rank: number;
    suit: Suit;
    owner: CardOwner;
    ownerIndex: number;
    order: number;
    isFaceUp: boolean;
    isShadow: boolean;
    isHighlight: boolean;
    dragEnabled: boolean;
    clickEnabled: boolean;
    isMoving: boolean;
    isDragging: boolean;
    isHint: boolean;
};

export interface Display {
    readonly gameSize: Size;
    readonly cardSize: Size;
    readonly isPortrait: boolean;
    readonly tableauCardSpace: number;
    calcCardPosition(card: Card, tableauCount?: number | undefined): Position;
}

export type Hint = {
    card1Id: string;
    card2Id: string | undefined | null;
    tableauIndex?: number | undefined | null;
    foundationIndex?: number | undefined | null;
    priority: number;
};

export type CustomizeOptions = {
    background: string;
    pattern: string;
};
