<template>
    <transition name="anim-fade">
        <div
            v-show="show"
            class="alert-box bg-green-700 shadow-xl text-lg rounded p-5 w-full md:mt-8 md:mr-8 md:w-2/3 lg:w-1/3 mx-auto top-0 right-0"
        >
            {{ text }}
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    setup() {
        const text = ref('Hello World');
        const show = ref(false);
        let timerId: number | null = null;

        subscribeTo(coreBus.showNotificationCmd$, (cmd) => {
            // if msg already in display close it and show new one
            if (show.value) {
                show.value = false;
            }
            if (timerId) {
                clearTimeout(timerId);
            }

            // show msg
            setTimeout(() => {
                text.value = cmd.text;
                show.value = true;
            });

            // set hide timer
            timerId = setTimeout(() => {
                timerId = null;
                show.value = false;
            }, 2000);
        });

        return {
            text,
            show,
        };
    },
});
</script>

<style scoped>
.alert-box {
    position: fixed;
    z-index: 999;
}
</style>
