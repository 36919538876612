
import { defineComponent, reactive, computed } from 'vue';
import { tracking } from '@/core/tracking';
import { coreBus } from '@/core/core-bus';
import { Game } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import ModalDialog from '@/components/ModalDialog.vue';
import Icon from '@/components/Icon.vue';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    components: { SButton, Icon, ModalDialog },

    setup() {
        const state = reactive({
            showModal: false,
            gameId: 0,
            displayName: '',
        });
        let game = Game.none;

        subscribeTo(coreBus.showChallengeFriendDialogCmd$, (cmd) => {
            state.showModal = true;
            game = cmd.game;
            state.gameId = cmd.gameId;
            state.displayName = coreUtil.gameDisplayName(cmd.game);
            tracking.event('challenge-friend-opened', { eventCategory: game.toString() });
            activityLog.sendGameActivity(game, cmd.gameId, 'challenge-friend-opened');
        });

        const handleClose = () => {
            state.showModal = false;
        };

        const isMobileShare = computed(() => {
            return !!navigator.share;
        });

        const url = computed(() => {
            const abrv = coreUtil.getGameAbbreviation(game);
            return `${window.location.origin}/r/${abrv}-${state.gameId}`;
        });

        const mailtoUrl = computed(() => {
            const subject = 'Solo Games Invitation';
            const body = `Hello, Your friend challenge you to a game of ${state.displayName}.%0A%0AClick on the link bellow to play.%0A%0A${url.value}%0A%0A%0A%0A`;
            return `mailto:?subject=${subject}&body=${body}`;
        });

        const share = () => {
            tracking.event('challenge-friend-share-clicked', { eventCategory: game.toString() });
            if (navigator.share) {
                navigator
                    .share({
                        title: 'Solo Games',
                        text: `Hello, Your friend challenge you to a game of ${state.displayName}`,
                        url: url.value,
                    })
                    .then(() => console.log('Successfully shared'))
                    .catch((error) => console.log('Error sharing:', error));
            }
        };

        return {
            state,
            handleClose,
            isMobileShare,
            mailtoUrl,
            url,
            share,
        };
    },
});
