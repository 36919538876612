<template>
    <div>Your game is starting...</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { coreUtil } from '@/core/core-util';
import { activityLog } from '@/core/activity-log';

export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();

        onMounted(() => {
            const key = route.params.gameKey;
            if (!key) {
                router.push('/');
                return;
            }

            const parts = key.toString().split('-');
            if (parts.length != 2) {
                router.push('/');
                return;
            }

            const abrv = parts[0];
            const gameId = parts[1];
            const game = coreUtil.getGameFromAbbreviation(abrv);
            if (!game) {
                router.push('/');
                return;
            }

            const path = coreUtil.getGameRoute(game);
            router.push(`${path}/${gameId}`);

            activityLog.sendGameActivity(game, parseInt(gameId, 10), 'challenge-friend-accepted');
        });
    },
});
</script>

<style scoped></style>
