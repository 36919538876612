import Fingerprint2 from 'fingerprintjs2';
import { Game, PlayerSettings } from '@/core/models';

class CoreLocalStorage {
    private fingerprintKey = 'solo.fp';

    private playerSettingsKey = 'solo.player-settings';

    private fingerprintCache: string | null = null;

    private playerSettingsCache: PlayerSettings | null = null;

    async getOrCreateFingerprint() {
        return new Promise<string>((resolve) => {
            if (this.fingerprintCache) {
                resolve(this.fingerprintCache);
                return;
            }

            const fp = localStorage.getItem(this.fingerprintKey);
            if (fp) {
                this.fingerprintCache = fp;
                resolve(fp);
                return;
            }

            Fingerprint2.get((components) => {
                const values = components.map(function (component) {
                    return component.value;
                });
                const hash = Fingerprint2.x64hash128(values.join(''), 31);
                localStorage.setItem(this.fingerprintKey, hash);
                this.fingerprintCache = hash;
                resolve(hash);
            });
        });
    }

    loadPlayerSettings(): PlayerSettings {
        if (this.playerSettingsCache) {
            return this.playerSettingsCache;
        }
        const str = localStorage.getItem(this.playerSettingsKey);
        if (!str) {
            this.playerSettingsCache = {
                name: null,
                mute: false,
            };
            return this.playerSettingsCache;
        }
        return JSON.parse(str);
    }

    updatePlayerSettings(data: Partial<PlayerSettings>) {
        const old = this.loadPlayerSettings();
        localStorage.setItem(
            this.playerSettingsKey,
            JSON.stringify({
                ...old,
                ...data,
            })
        );
        this.playerSettingsCache = null;
    }

    setGameInProgress(inProgress: boolean, game: Game) {
        const key = `${game.toString()}.game-in-progress`;
        if (inProgress) {
            localStorage.setItem(key, 'true');
        } else {
            localStorage.removeItem(key);
        }
    }

    isGameInProgress(game: Game) {
        const key = `${game.toString()}.game-in-progress`;
        return !!localStorage.getItem(key);
    }
}

export const coreLocalStorage = new CoreLocalStorage();
