
import { defineComponent, ref } from 'vue';
import { coreBus } from '@/core/core-bus';
import SButton from '@/components/SButton.vue';
import { subscribeTo } from '@/core/rxjs-helpers';

export default defineComponent({
    components: { SButton },

    setup() {
        const show = ref(false);

        subscribeTo(coreBus.showPauseOverlay$, () => {
            show.value = true;
            coreBus.overlayToggleCmd$.next(true);
        });

        const resume = () => {
            show.value = false;
            coreBus.resumeClickedEvent$.next();
            coreBus.overlayToggleCmd$.next(false);
        };

        return {
            show,
            resume,
        };
    },
});
