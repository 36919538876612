import { appStore } from '@/state/app.store';
import { CustomizeOptions, Game } from '@/core/models';

export class AppService {
    showFooter() {
        appStore.update({
            showFooter: true,
        });
    }

    hideFooter() {
        appStore.update({
            showFooter: false,
        });
    }

    setNavbarTitle(title: string) {
        appStore.update({
            navbarTitle: title,
        });
    }

    setActiveGame(game: Game) {
        appStore.update({
            activeGame: game,
        });
    }

    setAppHiddenStatus(hidden: boolean) {
        appStore.update({
            isAppHidden: hidden,
        });
    }

    setDisablePageScroll(disabled: boolean) {
        appStore.update({
            disablePageScroll: disabled,
        });
    }

    setCustomizeOptions(options: CustomizeOptions) {
        appStore.update({
            customizeOptions: options,
        });
    }
}

export const appService = new AppService();
