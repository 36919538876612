<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Pyramid</h1>
            <div class="mt-8">
                <router-link to="/pyramid/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Pyramid</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/pyramid" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    Pairs of exposed cards that total 13 are removed from the board. The object of
                    the game is to discard all cards in the pyramid. Aces count as 1, Jacks 11,
                    Queens 12 and Kings total 13 in themselves and as such, are discarded as a
                    single card rather than as a pair. Pyramid Rules
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.pyramid);
    },
});
</script>

<style scoped></style>
