
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    components: { Icon },

    props: {
        title: { type: String },
    },

    setup() {
        const open = ref(false);

        return {
            open,
        };
    },
});
