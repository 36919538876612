
import { ref, defineComponent } from 'vue';
import DailyChallenge from '@/components/DailyChallenge.vue';
import { Game } from '@/core/models';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: {
        DailyChallenge,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        const initGame = route.params.game ? ref(route.params.game) : ref(Game.klondike);

        const startGame = (d: { game: Game; gameId: number }) => {
            const url = `/${d.game.toString()}/play/${d.gameId}`;
            router.push(url);
        };

        return {
            startGame,
            initGame,
        };
    },
});
