<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Golf</h1>
            <div class="mt-8">
                <router-link to="/golf/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Golf</span></router-link
                >
            </div>
            <!--div class="mt-10">
            <router-link
                to="/daily-challenge/golf"
                class="rounded-md text-xl py-3 border border-green-600 shadow-gl px-5 bg-green-700 hover:bg-green-600 focus:outline-none"
            >
                <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
            >
        </div-->
            <div class="mt-8 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>The objective is to move all cards from the columns onto the waste</p>

                <h4 class="text-lg mt-5">Card Layout</h4>
                <p>
                    The game is played with a single pack of 52 playing cards. Seven columns are
                    dealt, each consisting of five face up and slightly overlapping cards. The
                    exposed card at the bottom of each column is available for play. The remaining
                    cards are placed face down to form the stock. Cards are turned one at a time
                    from the stock to a single waste pile where building occurs by transferring
                    exposed column cards according to the rules of the game.
                </p>

                <h4 class="text-lg mt-5">Rules</h4>
                <p>
                    You can move a card onto the Waste if it ranks one higher, or one lower than the
                    topmost card on the waste. E.g. if there's a 7 on the Waste you can move a 6 or
                    a 8 there. Initially the Waste is empty and you can move any card there.
                </p>
                <p>An Ace can go onto a King, or King onto an Ace</p>
                <p>
                    If you have no cards in the columns that can be moved to the Waste then you can
                    get a card from the Stock and turn it over to the Waste. Be aware though that
                    you can only go one time through the Stock, there are no resets allowed.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },

    setup() {
        apiClient.getWinnableGames(Game.golf);
    },
});
</script>

<style scoped></style>
