<template>
    <div class="w-full md:w-2/3 lg:w-1/2 mx-auto mt-2 text-center">
        <div class="s-container-1">
            <h1 class="text-4xl">Sudoku</h1>
            <div class="mt-8">
                <router-link to="/sudoku-easy/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Sudoku (Easy)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/sudoku-medium/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Sudoku (Medium)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/sudoku-hard/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Sudoku (Hard)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/sudoku-expert/play" class="s-button orange intro">
                    <icon icon="play" /><span class="ml-3">Play Sudoku (Expert)</span></router-link
                >
            </div>
            <div class="mt-5">
                <router-link to="/daily-challenge/sudoku-easy" class="s-button green intro">
                    <icon icon="calendar" /><span class="ml-3">Daily Challenge</span></router-link
                >
            </div>
            <div class="mt-10 rounded p-3 text-left">
                <h2 class="text-2xl text-center mb-3">How to Play</h2>

                <h4 class="text-lg">Objective</h4>
                <p>
                    The objective is to fill a 9x9 grid so that each column, each row, and each of
                    the nine 3x3 boxes (also called blocks or regions) contains the digits from 1 to
                    9.
                </p>
                <p>
                    A cell is the smallest block in the game. A row , column and region consists of
                    9 cells and the whole game consists of 81 cells. A region has thicker lines
                    surrounding it. This simply makes it easier to play the game.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { apiClient } from '@/core/api-client';
import { Game } from '@/core/models';

export default defineComponent({
    components: {
        Icon,
    },
    setup() {
        apiClient.getWinnableGames(Game.sudokuEasy);
        apiClient.getWinnableGames(Game.sudokuMedium);
        apiClient.getWinnableGames(Game.sudokuHard);
        apiClient.getWinnableGames(Game.sudokuExpert);
    },
});
</script>

<style scoped></style>
