import { Subject } from 'rxjs';
import { Card, Game, GameMetrics } from '@/core/models';

class CoreBus {
    showLeaderBoardDialogCmd$ = new Subject<ShowLeaderBoardDialogCmd>();

    showDailyChallengeDialogCmd$ = new Subject<ShowDailyChallengeDialogCmd>();

    showChallengeFriendDialogCmd$ = new Subject<ShowChallengeFriendDialogCmd>();

    startGameCmd$ = new Subject<StartGameCmd>();

    showPauseOverlay$ = new Subject();

    resumeClickedEvent$ = new Subject();

    sideMenuToggleCmd$ = new Subject<boolean>();

    overlayToggleCmd$ = new Subject<boolean>();

    overlayClickedEvent$ = new Subject();

    showGameStatisticDialogCmd$ = new Subject<ShowGameStatisticDialogCmd>();

    showNotificationCmd$ = new Subject<ShowNotificationCmd>();

    toggleGamesNavCmd$ = new Subject<boolean>();

    statsEvent$ = new Subject<StatsEvent>();

    gameMoveCompletedEvent$ = new Subject<GameMoveCompletedEvent | undefined>();

    undoMoveCmd$ = new Subject();

    openGameMenu$ = new Subject();

    autoFinishCmd$ = new Subject();

    showDonationDialogCmd$ = new Subject();

    cardDragEvent$ = new Subject<CardDragEvent>();

    cardMoveCmd$ = new Subject<CardMoveCmd>();

    cardClickEvent$ = new Subject<CardClickEvent>();

    shakeCardCmd$ = new Subject<ShakeCardCmd>();

    cardSlidOutDownCmd$ = new Subject<CardSlidOutDownCmd>();

    generateHintCmd$ = new Subject();

    stockClickEvent$ = new Subject();

    meldCardCmd$ = new Subject<MeldCardCmd>();

    moveCardToFoundationCmd$ = new Subject<MoveCardToFoundationCmd>();

    moveCardToEmptyTableauCmd$ = new Subject<MoveCardToEmptyTableauCmd>();

    recycleWasteCmd$ = new Subject();

    showCustomizeDialogCmd$ = new Subject();
}

export const coreBus = new CoreBus();

export type ShowLeaderBoardDialogCmd = {
    game: Game;
    gameId: number;
};

export type ShowDailyChallengeDialogCmd = {
    game: Game;
    gameId: number;
};

export type StartGameCmd = {
    game: Game;
    gameId: number | null | undefined;
};

export type ShowChallengeFriendDialogCmd = {
    game: Game;
    gameId: number;
};

export type ShowGameStatisticDialogCmd = {
    game: Game;
    hideMoveData?: boolean | undefined;
    hideScoreData?: boolean | undefined;
};

export type ShowNotificationCmd = {
    type: string;
    text: string;
};

export enum StatsEventType {
    gameStated,
    gameWon,
}

export type StatsEvent = {
    type: StatsEventType;
    game: Game;
    metrics?: GameMetrics | undefined;
};

export enum DragEventType {
    start,
    end,
    move,
}

export type CardDragEvent = {
    cardId: string;
    card: Card;
    type: DragEventType;
    x: number;
    y: number;
};

export type CardMoveCmd = {
    cardId: string;
    duration: number;
};

export type CardClickEvent = {
    cardId: string;
    card: Card;
};

export type ShakeCardCmd = {
    card: Card;
};

export type CardSlidOutDownCmd = {
    card: Card;
};

export type MeldCardCmd = {
    card: Card;
    destCard: Card;
};

export type MoveCardToFoundationCmd = {
    card: Card;
    foundationIndex: number;
};

export type MoveCardToEmptyTableauCmd = {
    card: Card;
    tableauIndex: number;
};

export type GameMoveCompletedEvent = {
    name: string;
};
